import { useInfiniteQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { selectProfile } from 'state/auth/selectors';

import api from 'services/Api';
const useWatchers = (cachedAuth) => {
  const fetchWatchers = async () => {
    try {
      const headers = {
        states: 'ACTIVE',
        orgId: `${cachedAuth.attributes.orgId[0]}`,
      };
      const response = await api.get(`core/iam/v1/users`, { headers });
      const watchers = await response.json();
      return watchers.data.users;
    } catch (e) {
      return Promise.reject(e);
    }
  };
  return useInfiniteQuery(['watchers'], fetchWatchers, {
    refetchOnWindowFocus: false,
    getFetchMore: (lastGroup) => lastGroup.meta.nextPage,
  });
};
export default useWatchers;
