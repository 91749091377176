import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { selectProfile } from 'state/auth/selectors';
import CreateDate from 'components/CreatedDate';
import Table from 'components/ReactTable';
import PriorityChip from 'components/PriorityChip';
import StatusText from 'components/StatusText';
import { selectUser, selectOrganization } from 'state/auth/selectors';
import useTickets from 'hooks/services/useTickets';
import SearchBox from 'components/SearchBox';
import CustomFilter from 'components/CustomFilter';
import Spinner from 'components/LoaderSpinner/Spinner';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { PATH_CSP_AGGREGATOR_SERVICE, PATH_IAM_SERVICE } from '../../constants';
import api from 'services/Api';
import {
  ticketFetchStart,
  ticketFetchSuccess,
  ticketFetchError,
} from 'state/tickets';
const useStyles = makeStyles((theme) => ({
  sectionTitle: theme.pageSectionTitle,
  filters: {
    padding: '17px 0px 30px 0px',
  },
  searchBox: {
    flex: 1,
    marginRight: '60px',
  },
  selectBox: {
    flex: 1,
  },
  mobileFilters: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: '10px 16px',
    },
  },
  mobileSearchBox: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '10px',
      marginRight: '0',
    },
  },
  truncate: {
    maxHeight: '1.5em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  spinner: {
    width: '100%',
    height: '100%',
  },
}));

const MyTickets = () => {
  const dispatch = useDispatch();
  const cachedAuth = useSelector(selectProfile);
  const [ticketId, setTicketId] = useState([]);
  const [entitlement, setEntitlement] = useState([]);
  const [allTicket, setAllTicket] = useState([]);
  const [ticketData, setTicketData] = useState(null);
  const [assigneeId, setAssigneeId] = useState([]);
  const classes = useStyles();
  const userId = useSelector(selectUser);
  const organizationId = useSelector(selectOrganization);
  const [filter, setFilter] = useState({
    status: 'NOT CLOSED',
    userPriority: 'All',
  });
  const [allTickets, setAllTickets] = useState([]);
  const history = useHistory();
  let [flattenedTickets, setFlattenedTickets] = useState([]);

  const { data: myTickets } = useTickets({
    orgId: organizationId,
    userId: userId,
  });
  useEffect(() => {
    let reducedflattenedTickets =
      myTickets &&
      myTickets.pages.reduce((accum, curr) => {
        return [...accum, ...curr.tickets];
      }, []);

    if (reducedflattenedTickets) {
      setFlattenedTickets(reducedflattenedTickets);
      setAllTickets(reducedflattenedTickets);
    }
  }, [myTickets]);
  const columns = React.useMemo(
    () => [
      {
        Header: 'Ticket No',
        Cell: ({ row }) =>
          `${
            row.original.ticketDisplayId
              ? row.original.ticketDisplayId
              : row.original.ticketId
          }`,
      },
      {
        Header: 'Title',
        accessor: 'title',
      },
      {
        Header: 'Created By',
        accessor: 'createdBy',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({
          cell: {
            row: { values },
          },
        }) => {
          return <StatusText priority={values.status} />;
        },
      },
      {
        Header: 'Priority',
        accessor: 'userPriority',
        Cell: ({
          cell: {
            row: { values },
          },
          isHovered,
        }) => {
          return (
            <PriorityChip
              priority={values.userPriority}
              isHovered={isHovered}
            />
          );
        },
      },
      {
        Header: 'Assignee',
        accessor: 'assigneeEmail',
      },
      {
        Header: 'Created Date',
        accessor: 'createdAt',
        Cell: ({
          cell: {
            row: { values },
          },
        }) => {
          return <CreateDate date={values.createdAt} />;
        },
      },
    ],
    []
  );
  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const response = await api.get(
          `${PATH_CSP_AGGREGATOR_SERVICE}/v1/tickets?organization-id=${cachedAuth.attributes.orgId[0]}&size=50`
        );

        if (response.ok) {
          const {
            data: { tickets },
          } = await response.json();
          setTicketData(tickets);
          return tickets;
        }

        throw new Error(`Error fetching ${response.url}`);
      } catch (error) {
        return Promise.reject(error);
      }
    };

    dispatch(ticketFetchStart());

    Promise.all([fetchTickets()])
      .then(([ticketData]) => {
        if (ticketData) {
          let tickets = ticketData.map(
            ({
              ticketId,
              ticketDisplayId,
              title,
              entitlementId,
              entitlementName,
              createdUserId: owner,
              assigneeEmail,
              assignedTo,
              assigneeId,
              createdBy,
              description,
              status,
              userPriority: userPriority,
              modifiedDate,
              createdAt,
              watchers,
              attachments,
            }) => {
              return {
                ticketId,
                ticketDisplayId,
                title,
                entitlementId,
                entitlementName,
                owner,
                assignedTo,
                assigneeEmail,
                assigneeId,
                createdBy,
                description,
                status,
                userPriority,
                modifiedDate,
                createdAt,
                watchers,
                attachments,
              };
            }
          );

          setAllTickets(tickets);
          dispatch(ticketFetchSuccess({ tickets }));
        }
      })
      .catch((error) => {
        dispatch(ticketFetchError());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myTickets]);
  const statusData = [
    {
      id: 1,
      name: 'OPENED',
    },
    {
      id: 2,
      name: 'IN_PROGRESS',
    },
    {
      id: 3,
      name: 'ONHOLD',
    },
    {
      id: 4,
      name: 'IN_REVIEW',
    },
    {
      id: 5,
      name: 'REOPEN',
    },
    {
      id: 6,
      name: 'CLOSED',
    },
  ];

  const PriorityData = [
    {
      id: 1,
      name: 'LOW',
    },
    {
      id: 2,
      name: 'MEDIUM',
    },
    {
      id: 3,
      name: 'HIGH',
    },
    {
      id: 4,
      name: 'URGENT',
    },
  ];

  const filterTickets = (event) => {
    const searchValue = event.target.value.toLowerCase();

    flattenedTickets = allTickets.filter((item) => {
      const formattedTicketId = item.ticketId.toString().padStart(6, '0');
      return (
        item.title.toLowerCase().includes(searchValue) ||
        item.description.toLowerCase().includes(searchValue) ||
        item.assigneeEmail.toLowerCase() === searchValue ||
        item.ticketDisplayId.toLowerCase().includes(searchValue) ||
        formattedTicketId === searchValue ||
        formattedTicketId.includes(searchValue.trim())
      );
    });

    setFlattenedTickets(flattenedTickets);
  };

  const handlePriorityChange = (event) => {
    setFilter({ ...filter, userPriority: event.target.value });
  };

  const handleStatusChange = (event) => {
    setFilter({ ...filter, status: event.target.value });
  };

  const handleTableRowClick = (id, { original: ticketData }) => {
    history.push({
      state: {
        ticketData,
        assigneeId,
        entitlement,
        ticketId,
        allTicket,
      },
      pathname: `tickets/${ticketData.entitlementId}/${ticketData.ticketId}`,
    });
  };

  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <span className={classes.sectionTitle}>My Tickets</span>
      </Grid>

      <Grid
        item
        container
        className={`${classes.filters} ${classes.mobileFilters}`}
      >
        <Grid
          item
          className={`${classes.searchBox} ${classes.mobileSearchBox}`}
        >
          <SearchBox
            placeholder="Search by title, assignee or ticket number..."
            onchangefunc={filterTickets}
          />
        </Grid>
        <Grid item container spacing={3} className={classes.selectBox}>
          <Grid item className={classes.selectBox}>
            <CustomFilter
              placeholder="Select status"
              selectItems={statusData}
              handleChange={handleStatusChange}
            />
          </Grid>
          <Grid item className={classes.selectBox}>
            <CustomFilter
              placeholder="Select priority"
              selectItems={PriorityData}
              handleChange={handlePriorityChange}
            />
          </Grid>
        </Grid>
      </Grid>
      {myTickets === undefined ? (
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          className={classes.spinner}
        >
          <Spinner color="#00BFFF" height={60} width={60} />
        </Grid>
      ) : (
        <Grid item container>
          {flattenedTickets.length === 0 ? (
            <div style={{ paddingTop: '50px', paddingBottom: '50px' }}>
              <span
                style={{
                  paddingLeft: '50px',
                  fontWeight: 'bold',
                  fontSize: '15px',
                }}
              >
                No ticket found. Looks like you haven't raised any issues yet
              </span>
            </div>
          ) : (
            <Table
              columns={columns}
              data={
                flattenedTickets &&
                flattenedTickets.filter(
                  (p) =>
                    (filter.status === 'NOT CLOSED' ||
                      p.status.toUpperCase() === filter.status.toUpperCase()) &&
                    (filter.userPriority === 'All' ||
                      p.userPriority.toUpperCase() ===
                        filter.userPriority.toUpperCase())
                )
              }
              onClickTableRow={handleTableRowClick}
            ></Table>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default MyTickets;
