import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import '../../index.css';
const useStyles = makeStyles((theme) => ({
  search: {
    background: '#EFF3FA',
    borderRadius: '4px',
    height: '40px',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
  },
  icon: {
    padding: '8px',
  },
}));

const SearchBox = ({ placeholder, disableUnderline = true, onchangefunc }) => {
  const classes = useStyles();

  return (
    <FormControl fullWidth>
      <Input
        id="search-box-placeholder"
        placeholder={placeholder}
        disableUnderline={disableUnderline}
        className={classes.search}
        onChange={onchangefunc}
        startAdornment={
          <InputAdornment position="start">
            <IconButton className={classes.icon}>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

export default SearchBox;
