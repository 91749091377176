import { Enumify } from 'enumify';

class TicketStatus extends Enumify {
  static OPENED = new TicketStatus();
  static IN_PROGRESS = new TicketStatus();
  static IN_REVIEW = new TicketStatus();
  static CLOSED = new TicketStatus();
  static ON_HOLD = new TicketStatus();
  static REOPENED = new TicketStatus();
  static _ = this.closeEnum();
}

export default TicketStatus;
