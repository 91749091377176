import React from 'react';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  message: {
    flex: '1',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonRoot: {
    alignSelf: 'flex-start',
    marginTop: '20px',
  },
}));

const ErrorFallback = ({ resetErrorBoundary }) => {
  const classes = useStyles();

  return (
    <div className={classes.root} role="alert">
      <Alert severity="error" classes={{ message: classes.message }}>
        <div className={classes.content}>
          <AlertTitle>Error</AlertTitle>
          <span>Oops! something went wrong</span>
          <Button
            variant="contained"
            size="small"
            disableElevation
            classes={{ root: classes.buttonRoot }}
            onClick={resetErrorBoundary}
          >
            Try again
          </Button>
        </div>
      </Alert>
    </div>
  );
};

export default ErrorFallback;
