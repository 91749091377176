import React, { createContext, useEffect, useState, useCallback } from 'react';
import Keycloak from 'keycloak-js';
import {
  RANDOLI_USER_ACCESS_TOKEN,
  ROUTE_AUTH_CALLBACK,
} from '../../constants';
import LoaderSpinner from 'components/LoaderSpinner';

import Grid from '@material-ui/core/Grid';

const keycloak = new Keycloak(`keycloak.json`);

const defaultConfig = {
  onLoad: 'check-sso',
  promiseType: 'native',
  enableLogging: false,
};

export const AuthContext = createContext({
  isInitialized: false,
  keycloak: null,
});

export const AuthProvider = ({ children, onEvent, initConfig }) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const initialPath = window.location.pathname + window.location.search;

  const onReady = useCallback(() => {
    setIsInitialized(true);
    localStorage.setItem(RANDOLI_USER_ACCESS_TOKEN, keycloak.token);

    onEvent && onEvent('onReady');
  }, [onEvent]);

  keycloak.onReady = onReady;

  useEffect(() => {
    keycloak.init({ ...defaultConfig, ...initConfig });

    return () => {
      keycloak.onReady = null;
    };
  }, [initConfig]);

  useEffect(() => {
    const isCallbackPath =
      window.location.pathname ===
        `${process.env.REACT_APP_CSP_DOMAIN}${ROUTE_AUTH_CALLBACK}` ||
      window.location.pathname === `${ROUTE_AUTH_CALLBACK}`;

    const path = initialPath.startsWith(`${process.env.REACT_APP_CSP_DOMAIN}`)
      ? initialPath.replace(`${process.env.REACT_APP_CSP_DOMAIN}`, '')
      : initialPath;

    if (!isCallbackPath) {
      sessionStorage.setItem('initialPath', path);
    }
  }, [initialPath]);

  if (!isInitialized) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ width: '100%', height: '100%' }}
      >
        <LoaderSpinner type="Bars" color="#00BFFF" height={70} width={70} />
      </Grid>
    );
  }

  return (
    <AuthContext.Provider value={{ isInitialized, keycloak }}>
      {children}
    </AuthContext.Provider>
  );
};

export { keycloak };
