import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMore from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import {
  Hidden,
  List,
  ListItem,
  ListItemText,
  Drawer,
  useMediaQuery,
} from '@material-ui/core';

import { useAuth } from 'hooks/useAuth';
import {
  selectRoles,
  selectUserName,
  selectGroups,
} from 'state/auth/selectors';
import { ROUTE_AUTH_CALLBACK, USER_GROUPS } from '../../constants';
import Avatar from '../Avatar';

const useStyles = makeStyles((theme) => ({
  header: {
    paddingLeft: theme.appContainer.paddingLeft,
    paddingRight: theme.appContainer.paddingRight,
    boxShadow: '0px 4px 9px rgba(0, 0, 0, 0.2)',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '20px',
      paddingRight: theme.appContainer.paddingLeft,
      boxShadow: '0px 4px 9px rgba(0, 0, 0, 0.2)',
    },
  },
  toolbarMargin: {
    ...theme.mixins.toolbar,
  },
  toolbarRoot: {
    minHeight: '70px',
  },
  companyButtonRoot: {
    color: 'white',
    fontFamily: 'Comfortaa',
    fontWeight: '700',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '17.84px',
    letterSpacing: '1px',
    padding: '0',
    [theme.breakpoints.down('sm')]: {
      color: 'white',
      fontFamily: 'Comfortaa',
      fontWeight: '700',
      fontStyle: 'normal',
      fontSize: '14px',
      lineHeight: '17.84px',
      letterSpacing: '1px',
    },
    [theme.breakpoints.down('xs')]: {
      color: 'white',
      fontFamily: 'Comfortaa',
      fontWeight: '700',
      fontStyle: 'normal',
      fontSize: '14px',
      lineHeight: '17.84px',
      letterSpacing: '1px',
    },
  },
  tabsRoot: {
    marginLeft: 'auto',
  },
  tabsFlexContainer: {
    minHeight: theme.mixins.toolbar.minHeight,
  },
  tabRoot: {
    fontWeight: '600',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '19.1px',
    letterSpacing: '1px',
    minWidth: 'auto',
    padding: 0,
    marginLeft: '35px',
  },
  tabIndicator: {
    top: '0px',
    border: '3px solid white',
  },
  tabActive: {
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
  },
  profileSettings: {
    flexWrap: 'nowrap',
    minHeight: '70px',
    width: 'auto',
    padding: '0 0 0 35px',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-end',
    },
  },
  dividerRoot: {
    borderLeft: '1px solid rgba(255, 255, 255, 1)',
    opacity: '20%',
  },
  expandMoreRoot: {
    padding: '0',
  },
  expandMoreLabel: {
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    borderRadius: '4px',
  },
  icon: {
    minWidth: '36px',
  },
}));

const routes = [
  {
    name: 'Dashboard',
    link: '/dashboard',
    activeIndex: 0,
    requiredRoles: ['csp_view_dashboard'],
  },
  {
    name: 'Tickets',
    link: '/tickets',
    activeIndex: 1,
    requiredRoles: ['csp_manage_tickets', 'csp_user'],
  },
  {
    name: 'Subscriptions',
    link: '/subscriptions',
    activeIndex: 1,
    requiredRoles: ['csp_manage_subscriptions'],
  },
  {
    name: 'Products & Services',
    link: '/entitlements',
    activeIndex: 2,
    requiredRoles: ['csp_manage_entitlements'],
  },
  // {
  //   name: 'Users',
  //   link: '/users',
  //   activeIndex: 3,
  //   requiredRoles: ['csp_manage_users'],
  // },
];

const Header = () => {
  const location = useLocation();
  const classes = useStyles();
  const matchesSM = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const { isAuthenticated, logout } = useAuth();
  const roles = useSelector(selectRoles);
  const userName = useSelector(selectUserName);
  const groups = useSelector(selectGroups);

  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [filteredRoutes, setFilteredRoutes] = useState(routes);

  // useEffect(() => {
  //   console.log('Mounted');
  // }, []);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  useEffect(() => {
    const filteredRoutes = routes
      .filter(({ requiredRoles }) => {
        return requiredRoles.some((role) => {
          return roles.includes(role);
        });
      })
      .map((route, index) => {
        route.activeIndex = index;
        return route;
      });

    setFilteredRoutes(filteredRoutes);
  }, [roles]);

  useEffect(() => {
    filteredRoutes.forEach((route) => {
      switch (location.pathname) {
        case `${route.link}`:
          if (value !== route.activeIndex) {
            setValue(route.activeIndex);
          }
          break;
        default:
          break;
      }
    });
  }, [filteredRoutes, value, location.pathname]);

  const orgAdminIndex = groups?.findIndex((group) => {
    return group?.endsWith(USER_GROUPS.ORG_ADMIN);
  });

  const groupName =
    orgAdminIndex >= 0 ? USER_GROUPS.ORG_ADMIN : USER_GROUPS.CSP_USER;

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  const handleMoreClick = (e) => {
    setAnchorEl(e.currentTarget);
    setOpenMenu(true);
  };

  const handleClose = (e) => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const handleMenuItemClick = (e, i) => {
    handleClose();
    setValue(-1);
  };

  const handleLogout = () => {
    const redirectUri = `${process.env.REACT_APP_CSP_DOMAIN}${process.env.PUBLIC_URL}${ROUTE_AUTH_CALLBACK}`;
    logout(redirectUri);
  };

  return (
    <>
      <AppBar position="fixed" elevation={0} classes={{ root: classes.header }}>
        <Toolbar disableGutters classes={{ root: classes.toolbarRoot }}>
          <Hidden mdUp implementation="css">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>

          <Button
            component={NavLink}
            to="/"
            onClick={() => setValue(0)}
            disableRipple
            classes={{ root: classes.companyButtonRoot }}
          >
            <span className={classes.CompanyName}>
              {matchesSM ? 'Randoli CSP' : 'Randoli Customer Service Portal'}
            </span>
          </Button>

          <Hidden smDown>
            <Tabs
              value={value < 0 ? false : value}
              onChange={handleChange}
              classes={{
                root: classes.tabsRoot,
                flexContainer: classes.tabsFlexContainer,
                indicator: classes.tabIndicator,
              }}
            >
              {filteredRoutes.map((route, index) => (
                <Tab
                  disableRipple
                  key={`${route}${index}`}
                  classes={{ root: classes.tabRoot }}
                  component={NavLink}
                  label={route.name}
                  to={route.link}
                />
              ))}
            </Tabs>
          </Hidden>

          {isAuthenticated && (
            <Grid
              container
              alignItems="center"
              classes={{ root: classes.profileSettings }}
            >
              <Divider flexItem classes={{ root: classes.dividerRoot }} />{' '}
              {matchesSM ? (
                <Avatar userName={userName.firstName} groupName={groupName} />
              ) : (
                <Avatar userName={userName.fullName} groupName={groupName} />
              )}
              <IconButton
                aria-label="settings"
                aria-controls="settings-menu"
                aria-haspopup="true"
                onClick={handleMoreClick}
                color="inherit"
                classes={{
                  root: classes.expandMoreRoot,
                  label: classes.expandMoreLabel,
                }}
              >
                <ExpandMore />
              </IconButton>
              <Menu
                id="profile-menu"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                open={openMenu}
                onClose={handleClose}
              >
                <MenuItem
                  to="/userprofile"
                  onClick={handleMenuItemClick}
                  component={NavLink}
                >
                  <ListItemIcon className={classes.icon}>
                    <AccountCircleIcon />
                  </ListItemIcon>
                  <Typography>User Profile</Typography>
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon className={classes.icon}>
                    <PowerSettingsNewIcon />
                  </ListItemIcon>
                  <Typography>Logout</Typography>
                </MenuItem>
              </Menu>
            </Grid>
          )}
        </Toolbar>
      </AppBar>
      <div className={classes.toolbarMargin} />

      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="left"
          open={drawerOpen}
          onClose={toggleDrawer}
        >
          <List>
            {filteredRoutes.map((route, index) => (
              <ListItem
                button
                component={NavLink}
                to={route.link}
                key={route.name}
                onClick={toggleDrawer}
              >
                <ListItemText primary={route.name} />
              </ListItem>
            ))}
          </List>
        </Drawer>
      </Hidden>
    </>
  );
};

export default Header;
