import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import useHover from 'hooks/useHover';
import PriorityChip from 'components/PriorityChip';
import Priority from 'schema/priority';

const useStyles = makeStyles((theme) => ({
  ticketCardRoot: {
    position: 'relative',
    display: 'flex',
    minWidth: '100%',
    height: '80px',
    flexDirection: 'column',
    boxShadow: '0px 1px 8px rgba(20, 46, 110, 0.1)',
    borderRadius: '10px',
    padding: '20px 16px 16px 16px',
    '&:active': {
      background: theme.palette.action.active,
    },
    '&:hover': {
      background: theme.palette.action.hover,
      boxShadow: '2px 2px 2px 2px rgba(20, 46, 110, 0.1)',
    },
  },
  ticketIdPrioritiy: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '5px',
  },
  ticketId: {
    fontWeight: '700',
    fontStyle: 'normal',
    fontSize: '15px',
    lineHeight: '20px',
    letterSpacing: '-0.2px',
    color: 'rgba(98, 103, 153, 1)',
  },
  style: {
    padding: '2px 8px 8px 4px',
  },
}));

const TicketCard = ({
  style,
  id,
  title,
  priority = Priority.HIGH.enumKey,
  ticketData,
}) => {
  const classes = useStyles();
  const [hoverRef, isHovered] = useHover();
  const history = useHistory();

  const [ticketId, setTicketId] = useState([]);
  const [entitlement, setEntitlement] = useState([]);
  const [allTicket, setAllTicket] = useState([]);
  const [assigneeId, setAssigneeId] = useState([]);

  const onClickCard = () => {
    history.push({
      state: {
        ticketData,
        assigneeId,
        entitlement,
        ticketId,
        allTicket,
      },

      pathname: `tickets/${ticketData.entitlementId}/${ticketData.ticketId}`,
    });
  };

  return (
    <div style={style} className={classes.style} onClick={onClickCard}>
      <div className={classes.ticketCardRoot} ref={hoverRef}>
        <div className={classes.ticketIdPrioritiy}>
          <span className={classes.ticketId}>{id}</span>
          <PriorityChip priority={priority} isHovered={isHovered} />
        </div>
        <div>
          <span className={classes.ticketTitle}>{title}</span>
        </div>
      </div>
    </div>
  );
};

export default TicketCard;
