import { createSelector } from 'reselect';

export const selectTickets = (state) => state.tickets;

export const selectTicketTickets = createSelector(
  [selectTickets],
  (tickets) => {
    return tickets.tickets;
  }
);
