import React from 'react';

import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  select: (props) => ({
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'normal',
    height: props?.height || '40px',
    padding: '0 14px',
    display: 'flex',
    alignItems: 'center',
  }),
  labeledBtn: {
    padding: '6px 16px',
  },
}));

const CustomSelect = ({
  id,
  name,
  inputLabelId,
  placeholder,
  defaultValue = '',
  register,
  selectItems,
  data,
  value,
  onChange,
  boxHeight,
}) => {
  const classes = useStyles({ height: boxHeight });

  return (
    <>
      <InputLabel id={inputLabelId}>{placeholder}</InputLabel>
      <Select
        id={id}
        labelId={inputLabelId}
        label={placeholder}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        classes={{
          root: classes.select,
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
          PaperProps: {
            style: {
              maxHeight: '200px',
            },
          },
        }}
        inputProps={{
          name,
          inputRef: (ref) => {
            if (!ref) return;
            register({ name, value: ref.value });
          },
        }}
      >
        {selectItems.map((item) => (
          <MenuItem
            key={item.id}
            value={item.value ? item.value : item.name}
            data-id={item.id}
          >
            {data && (
              <>
                {item.id} <> : </>
              </>
            )}
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default CustomSelect;
