import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectProfile } from 'state/auth/selectors';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { FormControl } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Divider } from '@material-ui/core';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';

import CustomSelect from 'components/CustomSelect';
import AlertDialog from 'components/AlertDialog';
import Dependency from 'components/Dependency';

import useCreateDependency from 'hooks/services/useCreateDependency';
import useDependency from 'hooks/services/useDependency';
import api from 'services/Api';
import { name } from 'state/tickets';
import TicketList from 'components/TicketList';

const useStyles = makeStyles((theme) => ({
  componentHeading: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '0.9rem',
    fontWeight: 'bold',
  },
  fullWidth: {
    flexBasis: '100%',
  },
  add: {
    display: 'flex',
    flexDirection: 'inherit',
    alignItems: 'center',
  },
  addDepedencyTopic: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    color: '#0F5EF7',
  },

  dependencyDialogPaper: {
    maxHeight: '80vh',
    maxWidth: '45vw',
    height: '80vh',
  },
  rootContainer: {
    flexDirection: 'column',
    height: '100%',
  },
  actionContainer: {
    alignItems: 'center',
  },
  dependencyContainer: {
    flex: 1,
  },
  text: {
    display: 'flex',
  },
  relation: {
    width: '100%',
    padding: '3%',
  },
  selectedDependencies: {
    height: '100%',
    overflowY: 'auto',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '4px',
    marginTop: '4px',
    marginBottom: '4px',
    textAlign: 'center',
  },
  dividerRoot: {
    height: '2.5px',
    marginTop: '20px',
    marginBottom: '12px',
  },
  box: {
    height: '10vh',
    overflowY: 'auto',
    borderRadius: '4px',
    marginTop: '4px',
    marginBottom: '4px',
  },

  actionSpacing: {
    paddingRight: '24px',
    paddingBottom: '24px',
  },
  selectContainer: {
    flex: '0 0 45%',
  },
  addButton: {
    flex: '1',
  },
}));

const Schema = yup.object().shape({
  ticketId: yup.string().required('Ticket Id is required.'),
  relationship: yup.string().required('Relationship is required.'),
});

const dependenciesList = [
  {
    value: 'BLOCKS',
    name: 'Blocks',
  },
  {
    value: 'CLONED_BY',
    name: 'Cloned by',
  },
  {
    value: 'DUPLICATED_BY',
    name: 'Duplicated by',
  },
  {
    value: 'DUPLICATES',
    name: 'Duplicates',
  },
  {
    value: 'FOLLOWED_BY',
    name: 'Followed by',
  },
  {
    value: 'FOLLOWS',
    name: 'Follows',
  },
  {
    value: 'SPLIT_FROM',
    name: 'Split from',
  },
  {
    value: 'SPLIT_TO',
    name: 'Split to',
  },
  {
    value: 'CAUSED_BY',
    name: 'Caused by',
  },
  {
    value: 'CAUSES',
    name: 'Causes',
  },
  {
    value: 'RELATES_TO',
    name: 'Relates to',
  },
];

// To pass the return type correctly to the custom select
const select = (arr) => {
  let count = 0;
  return arr.map((x) => {
    if (x.name && x.value) {
      return { id: count++, ...x };
    } else {
      return { id: count++, name: x };
    }
  });
};

const ManageDependency = ({ ticketId, allTicket, ticketData }) => {
  const classes = useStyles();
  const cachedAuth = useSelector(selectProfile);
  const [dependencies, setDependencies] = useState([]);
  const [openUpdateDependencyDialog, setOpenUpdateDependencyDialog] =
    useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [ticketValue, setTicketValue] = useState(ticketId);
  const [selectedTicketId, setselectedTicketId] = useState();
  const [selectedTicketName, setselectedTicketName] = useState('');
  const [relationshipValue, setRelationshipValue] = useState('');
  const { mutateAsync: createDependency } = useCreateDependency(
    ticketData ? ticketData.ticketId : '',
    ticketData ? ticketData.entitlementId : ''
  );
  const [linkedTicketEntitlementId, setLinkedTicketEntitlementId] = useState();
  const { data: datalist } = useDependency({ ticketData });

  const { errors, register } = useForm({
    resolver: yupResolver(Schema),
    mode: 'onBlur',
  });

  const ticketWithNameAndId = allTicket
    .filter((ticket) => ticket.entitlementId === ticketData.entitlementId)
    .map((ticket) => {
      return {
        id: ticket.ticketId,
        ticketId: ticket.ticketId,
        name: ticket.title,
        value: ticket.ticketId,
      };
    });

  const getTicketName = (id) => {
    const ticket = allTicket.find((ticket) => ticket.ticketId === id);
    setLinkedTicketEntitlementId(ticket?.entitlementId);
    setselectedTicketName(ticket?.title);
    return ticket?.title;
  };

  useEffect(() => {
    const ArrayDependency = [];
    const a = datalist?.pages?.map((page) => {
      const b = page?.links?.map((link) => {
        if (link?.entitlementId === ticketData.entitlementId) {
          ArrayDependency.push({
            ticketValue: link?.linkedTicketId,
            selectedTicketName: getTicketName(link?.linkedTicketId),
            relationshipValue: link?.linkType,
            key: dependencies.length,
          });
        }
      });
    });

    if (ArrayDependency) {
      setDependencies([...dependencies, ...ArrayDependency]);
    }
  }, [datalist]);

  const addDdependency = async () => {
    if (ticketValue !== '' && relationshipValue !== '') {
      setDependencies([
        ...dependencies,
        {
          ticketValue,
          selectedTicketName,
          relationshipValue,
          key: dependencies.length,
        },
      ]);
      const newDependency = {
        ticketId: ticketData.ticketId,
        title: selectedTicketName,
        ticketName: ticketData.title,
        linkedTicketId: ticketValue,
        linkType: relationshipValue,
        entitlementId: linkedTicketEntitlementId,
        createdUserEmail: cachedAuth.email,
        modifiedUserId: ticketData.owner,
      };
      createDependency(newDependency);
      setTicketValue('');
      setRelationshipValue('');
    }
  };

  const removeDependency = (key) => {
    setDependencies(() =>
      dependencies.filter((dependency) => dependency.key !== key)
    );
  };

  const handleSaveDependencies = () => {
    if (ticketValue !== 0 && relationshipValue !== 0) {
      setOpenUpdateDependencyDialog(false);
    }
  };

  const handleOpenUpdateDependencyDialog = () => {
    setOpenUpdateDependencyDialog(true);
  };

  const handleClose = () => {
    setOpenUpdateDependencyDialog(false);
    setTicketValue('');
    setRelationshipValue('');
  };

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  const handleOk = () => {
    handleClose();
    handleAlertClose();
  };

  const handlePreClose = () => {
    if (dependencies.length !== 0) {
      setOpenAlert(true);
    } else {
      handleClose();
    }
  };
  const handlechangeTicket = (e) => {
    getTicketName(e.target.value);
    setTicketValue(e.target.value);
  };

  const handleChangeRelationship = (e) => {
    setRelationshipValue(e.target.value);
  };
  return (
    <>
      <Grid container alignItems="center">
        <Grid item container alignItems="center" justifyContent="space-between">
          <Grid item>
            <span className={classes.componentHeading}>Dependencies</span>
          </Grid>
          <Grid item className={classes.add}>
            <Button
              className={classes.addDepedencyTopic}
              variant="text"
              onClick={handleOpenUpdateDependencyDialog}
            >
              Add dependency{' '}
              <AddCircleOutlineRoundedIcon style={{ color: '#0F5EF7' }} />
            </Button>
          </Grid>
        </Grid>
        <Grid item className={classes.fullWidth}>
          <div className={classes.box}>
            {dependencies.map(({ key, ticketId, relationship }) => (
              <Dependency ticketId={ticketId} relationship={relationship} />
            ))}
          </div>
        </Grid>
      </Grid>

      <Dialog
        open={openUpdateDependencyDialog}
        onClose={handlePreClose}
        fullWidth
        classes={{ paper: classes.dependencyDialogPaper }}
      >
        <DialogTitle>Dependencies</DialogTitle>
        <DialogContent>
          <>
            <AlertDialog
              buttonConfirmText="ok"
              buttonCancelText="close"
              title="Dependency Details Not Saved"
              open={openAlert}
              handleClose={handleAlertClose}
              handleOk={handleOk}
            >
              Are you sure you want to continue without saving?
            </AlertDialog>
            <form className={classes.form}>
              <Grid container spacing={2} className={classes.rootContainer}>
                <Grid
                  item
                  container
                  spacing={1}
                  className={classes.actionContainer}
                >
                  <Grid item className={classes.selectContainer}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      size="small"
                      className={classes.text}
                    >
                      <CustomSelect
                        id="ticket"
                        name="ticket"
                        inputLabelId="ticket"
                        placeholder="Ticket"
                        register={register}
                        selectItems={ticketWithNameAndId}
                        data={allTicket}
                        onChange={handlechangeTicket}
                        value={ticketValue}
                      />
                    </FormControl>
                    {errors.ticketId && <p>{errors.ticketId.message}</p>}
                  </Grid>
                  <Grid item className={classes.selectContainer}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      size="small"
                      className={classes.text}
                    >
                      <CustomSelect
                        id="relationship"
                        name="relationship"
                        inputLabelId="relationship"
                        placeholder="Relationship"
                        register={register}
                        selectItems={select(dependenciesList)}
                        onChange={handleChangeRelationship}
                        value={relationshipValue}
                      />
                    </FormControl>
                    {errors.relationship && (
                      <p>{errors.relationship.message}</p>
                    )}
                  </Grid>
                  <Grid item className={classes.addButton}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => {
                        addDdependency();
                      }}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
                <Grid item>
                  <Divider className={classes.dividerRoot} />
                </Grid>
                {dependencies.length === 0 ? (
                  <></>
                ) : (
                  <Grid item className={classes.dependencyContainer}>
                    <div className={classes.selectedDependencies}>
                      {dependencies.map(
                        ({
                          key,
                          ticketValue,
                          selectedTicketName,
                          relationshipValue,
                        }) => (
                          <div>
                            <Grid container key={key}>
                              <Grid item xs={4}>
                                <p fontWeight="fontWeightBold">
                                  {' '}
                                  {ticketValue} : {selectedTicketName}
                                </p>
                              </Grid>
                              <Grid item xs={4}>
                                <p fontWeight="fontWeightBold">
                                  {' '}
                                  {relationshipValue}{' '}
                                </p>
                              </Grid>
                              <Grid item xs={4}>
                                <Link
                                  onClick={() => removeDependency(key)}
                                  fontWeight="fontWeightBold"
                                >
                                  <p> Remove </p>
                                </Link>
                              </Grid>
                            </Grid>
                          </div>
                        )
                      )}
                    </div>
                  </Grid>
                )}
              </Grid>
            </form>
          </>
        </DialogContent>

        <DialogActions className={classes.actionSpacing}>
          <Button
            autoFocus
            variant="contained"
            color="primary"
            onClick={() => {
              handleSaveDependencies();
            }}
          >
            Save
          </Button>
          <Button variant="contained" color="primary" onClick={handlePreClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ManageDependency;
