import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectAuthenticated, selectRoles } from 'state/auth/selectors';
import { useAuth } from 'hooks/useAuth';
import {
  RANDOLI_USER_ACCESS_TOKEN,
  ROUTE_UN_AUTHORIZED,
} from '../../constants';

const minRoles = [`${process.env.REACT_APP_CSP_MINIMUM_ROLE}`];

const AuthCallback = () => {
  const { handleAuthentication } = useAuth();
  const history = useHistory();
  const isAuthenticated = useSelector(selectAuthenticated);
  const userRoles = useSelector(selectRoles);
  const initialPath = sessionStorage.getItem('initialPath');

  useEffect(() => {
    handleAuthentication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const accessToken = localStorage.getItem(RANDOLI_USER_ACCESS_TOKEN);
    if (isAuthenticated && accessToken) {
      const hasAtleastOneRole = userRoles.some((role) => {
        return minRoles.includes(role);
      });

      if (!hasAtleastOneRole) {
        history.push(ROUTE_UN_AUTHORIZED);
      } else {
        history.push({
          pathname: initialPath,
        });
      }
    }
  }, [isAuthenticated, userRoles, history, initialPath]);

  return null;
};

export default AuthCallback;
