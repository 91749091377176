import { PATH_CSP_AGGREGATOR_SERVICE } from '../../constants';
import { useInfiniteQuery } from 'react-query';

import api from 'services/Api';

const useTickets = (queryParams) => {
  const fetchTickets = async (nextPage) => {
    try {
      if (queryParams?.orgId) {
        const query = new URLSearchParams({
          'organization-id': queryParams?.orgId,
          page: nextPage,
        });

        if (queryParams?.entitlementId) {
          query.append('entitlement-ids', queryParams?.entitlementId);
        }

        if (queryParams?.ticketId) {
          query.append('ticket-ids', queryParams?.ticketId);
        }

        if (queryParams?.status) {
          query.append('status', queryParams?.status);
        }

        if (queryParams?.userId) {
          query.append('assignee-id', queryParams?.userId);
        }

        query.append('size', '50');

        const response = await api.get(
          `${PATH_CSP_AGGREGATOR_SERVICE}/v1/tickets/?${query.toString()}`
        );

        const tickets = await response.json();

        return tickets.data;
      } else {
        return [];
      }
    } catch (e) {
      return Promise.reject(e);
    }
  };
  return useInfiniteQuery(
    ['tickets', queryParams],
    ({ pageParam = 0 }) => fetchTickets(pageParam),
    {
      refetchOnWindowFocus: false,
      getFetchMore: (lastGroup) => lastGroup.meta.nextPage,
    }
  );
};
export default useTickets;
