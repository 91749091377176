import { useMutation, useQueryClient } from 'react-query';

import api from 'services/Api';

const useUpdatePriority = (ticketData, value) => {
  const QueryClient = useQueryClient();
  const url = `csp/ticket/v1/tickets/${ticketData.entitlementId}/${ticketData.ticketId}/priority?ticket-priority=${value}`;

  return useMutation(
    (obj) => api.patch(url, JSON.stringify(obj)).then((x) => x.json()),
    {
      onSuccess: async () => {
        QueryClient.invalidateQueries('success');
      },
    },
    {
      onError: async () => {
        console.log('error');
      },
    }
  );
};

export default useUpdatePriority;
