import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Button,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dialogBox: {
    margin: 0,
    position: 'absolute',
    padding: 0,
    textAlign: 'center',
    alignContent: 'center',
    alignItems: 'center',
    fontFamily: 'Nunito',
    lineHeight: '20px',
  },
  dialogPaper: {
    borderRadius: '10px',
    padding: '10px',
    minWidth: '400px',
  },
  dialogAction: {
    justifyContent: 'center',
    marginTop: '22px',
  },
  icon: {
    alignSelf: 'center',
    width: '42px',
    height: '42px',
    position: 'absolute',
    marginTop: '25px',
    color: '#ff7c7c',
  },
  titleBox: {
    padding: 0,
    marginTop: '80px',
    textAlign: 'center',
    alignSelf: 'center',
    color: '#001847',
    fontWeight: 800,
    '& h6': {
      fontWeight: 800,
      fontSize: '1.125rem',
    },
  },
  contentText: {
    marginTop: '8px',
    textAlign: 'center',
    alignSelf: 'center',
    color: '#001847',
    fontSize: '1rem',
    fontWeight: 400,
    fontFamily: 'Nunito',
    lineHeight: '20px',
    maxWidth: '450px',
    padding: '0 5px',
  },
  cancelButton: {
    padding: 0,
    width: '155px',
    height: '40px',
    backgroundColor: '#ffffff',
    border: '1px solid #626799',
    borderRadius: '4px',
    color: theme.palette.common.blue,
    margin: '10px 25px 15px',
    letterSpacing: '0.5px',
    '&:hover': {
      backgroundColor: '#ffffff !important',
      border: '1px solid #626799 !important',
    },
  },
  confirmButton: (props) => ({
    padding: 0,
    width: '155px',
    height: '40px',
    backgroundColor: props.backgroundColor ? props.backgroundColor : '#FF7C7C',
    borderRadius: '4px',
    margin: '10px 25px 15px',
    letterSpacing: '0.5px',
    '&:hover': {
      backgroundColor: `${
        props.backgroundColor ? props.backgroundColor : '#FF7C7C'
      } !important`,
      border: 'none !important',
    },
  }),
}));

const ConfirmationDialog = ({
  open,
  onClose,
  buttonConfirmText,
  buttonCancelText,
  backgroundColor,
  onConfirm,
  icon,
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme, backgroundColor });
  const handleClick = (e) => {
    e.stopPropagation();
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={classes.dialogBox}
      onClick={handleClick}
      PaperProps={{
        className: classes.dialogPaper,
      }}
    >
      {icon}
      <DialogTitle className={classes.titleBox}>
        {'Are you sure you want to delete this file?'}
      </DialogTitle>

      <DialogActions className={classes.dialogAction}>
        <>
          <Button
            data-cy="btn-cancel"
            onClick={onClose}
            className={classes.cancelButton}
            autoFocus
          >
            {buttonCancelText}
          </Button>
          <Button
            data-cy="btn-confirm"
            onClick={onConfirm}
            className={classes.confirmButton}
            variant="contained"
            startIcon={<span />}
          >
            {buttonConfirmText}
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
