import { configureStore } from '@reduxjs/toolkit';

import { reducer as AuthReducer } from './auth';
import { reducer as DashboardReducer } from './dashboard';
import { reducer as TicketReducer } from './tickets';
import { reducer as UserReducer } from './users';
import { reducer as EntitlementReducer } from './entitlements';

export default () => {
  const store = configureStore({
    reducer: {
      auth: AuthReducer,
      dashboard: DashboardReducer,
      tickets: TicketReducer,
      users: UserReducer,
      entitlements: EntitlementReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  });

  return store;
};
