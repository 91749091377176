import { useMutation, useQueryClient } from 'react-query';
import api from 'services/Api';

const useEntitlementSubscription = () => {
  const queryClient = useQueryClient();

  const subscriptionUrl = 'core/entitlements/v1/subscriptions';

  return useMutation(
    (payload) =>
      api
        .post(subscriptionUrl, JSON.stringify(payload))
        .then((response) => response.json()),
    {
      onSuccess: (data) => {
        console.log('Mutation succeeded:', data);
        queryClient.invalidateQueries('subscriptions');
      },
      onError: (error) => {
        console.log('Mutation failed:', error);
      },
    }
  );
};

export default useEntitlementSubscription;
