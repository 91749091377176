import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import {
  entitlementFetchSuccess,
  entitlementFetchError,
} from 'state/entitlements';
import { selectCurrentEntitlements } from 'state/entitlements/selectors';
import { selectProfile } from 'state/auth/selectors';

import Table from 'components/ReactTable';
import SearchBox from 'components/SearchBox';
import CustomFilter from 'components/CustomFilter';

import useEntitlements from 'hooks/services/useEntitlements';
const useStyles = makeStyles((theme) => ({
  headingTitle: theme.pageHeading,
  root: {
    display: 'flex',
  },
  gridContainer: {
    paddingTop: '10px',
  },
  rightCommandContainer: {
    alignItems: 'center',
  },
  tableContainer: { flex: 1 },
  tableStyles: {
    maxHeight: 'calc(100vh - 280px)',
  },
  searchBox: {
    marginRight: '50px',
    [theme.breakpoints.up('md')]: {
      flex: 1,
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: '0',
    },
  },
  selectBox: {
    [theme.breakpoints.up('md')]: {
      flex: 1,
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '20px',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '10px',
      paddingLeft: '0',
    },
  },
}));

const status = [
  {
    id: 1,
    name: 'ACTIVE',
  },
  { id: 2, name: 'INACTIVE' },
  { id: 3, name: 'SUSPENDED' },
];

const entitlement = [
  {
    value: 'CONTRACT',
    name: 'Contract',
  },
  {
    value: 'SUBSCRIPTION',
    name: 'Subscription',
  },
];

const Entitlements = () => {
  const classes = useStyles();
  let cachedEntitlements = useSelector(selectCurrentEntitlements);
  const cachedAuth = useSelector(selectProfile);
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    status: 'All',
    entitlementType: 'All',
  });
  let [allEntitlements, setAllEntitlements] = useState([]);
  let [flattenedEntitlement, setFlattenedEntitlements] = useState([]);
  const { data: entitlements } = useEntitlements(cachedAuth);
  let flattenedEntitlements;
  useEffect(() => {
    flattenedEntitlements =
      entitlements &&
      entitlements.pages.reduce((accum, curr) => {
        return [...accum, ...curr];
      }, []);
    if (flattenedEntitlements) {
      setAllEntitlements(flattenedEntitlements);
      setFlattenedEntitlements(flattenedEntitlements);
    }
  }, [entitlements]);

  const columns = useMemo(
    () => [
      {
        Header: 'Product / Service',
        accessor: 'name',
      },
      {
        Header: 'Status',
        accessor: 'entitlementStatus',
      },
      {
        Header: 'Tickets',
        accessor: 'ticketCount',
      },
      {
        Header: 'Start Date',
        accessor: 'startDate',
      },
      {
        Header: 'End Date',
        accessor: 'endDate',
      },
    ],
    []
  );

  const handleFilterState = (e) => {
    setFilter({ ...filter, status: e.target.value });
  };

  const handleFilterEntitlementType = (e) => {
    setFilter({ ...filter, entitlementType: e.target.value });
  };

  // To pass the return type correctly to the custom select
  // eslint-disable-next-line no-unused-vars
  const select = (arr) => {
    let count = 0;
    return arr.map((x) => {
      if (x.name && x.value) {
        return { id: count++, ...x };
      } else {
        return { id: count++, name: x };
      }
    });
  };

  useEffect(() => {
    // setOrganizationId(cachedAuth.attributes.orgId[0]);
    const getEntitlements = async () => {
      try {
        dispatch(
          entitlementFetchSuccess({
            entitlements: flattenedEntitlements || [],
          }),
          setAllEntitlements(flattenedEntitlements)
        );
      } catch (error) {
        dispatch(entitlementFetchError());
        return Promise.reject(error);
      }
    };
    if (cachedEntitlements.length === 0) {
      getEntitlements();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterEntitlements = (event) => {
    flattenedEntitlement = allEntitlements.filter(
      (item) =>
        item.name
          .toLocaleLowerCase()
          .indexOf(event.target.value.toLocaleLowerCase()) !== -1
    );
    setFlattenedEntitlements(flattenedEntitlement);
    dispatch(
      entitlementFetchSuccess({
        entitlements: cachedEntitlements || [],
      })
    );
  };

  return (
    <Grid container alignContent="flex-start">
      <Grid
        item
        container
        className={`${classes.filters} ${classes.mobileView}`}
        justifyContent="space-between"
      >
        <Grid item xs={12}>
          <span className={classes.headingTitle}>My Products & Services</span>
        </Grid>

        <Grid
          xs={12}
          container
          justify="space-between"
          classes={{ container: classes.gridContainer }}
        >
          <Grid item xs={12} sm={6} md={4} className={classes.searchBox}>
            <SearchBox
              placeholder="Search..."
              onchangefunc={filterEntitlements}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2} className={classes.selectBox}>
            <CustomFilter
              placeholder="Select status"
              selectItems={status}
              handleChange={handleFilterState}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Table
            columns={columns}
            tableMaxHeight="calc(100vh - 240px)"
            data={
              flattenedEntitlement &&
              flattenedEntitlement.filter(
                (p) =>
                  filter.status === 'All' ||
                  p.entitlementStatus.toUpperCase() ===
                    filter.status.toUpperCase()
              )
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Entitlements;
