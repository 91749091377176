import { useInfiniteQuery } from 'react-query';
import api from 'services/Api';

const useSubscriptions = (organizationId) => {
  const fetchSubscriptions = async ({ pageParam = 0 }) => {
    try {
      const query = new URLSearchParams({
        orgId: organizationId,
        page: pageParam,
        size: '10',
      });

      const response = await api.get(
        `core/entitlements/v1/subscriptions/orgs/${organizationId}?${query.toString()}`
      );

      const subscriptions = await response.json();

      return {
        data: subscriptions.data,
        nextPage: pageParam + 1,
        hasNextPage: subscriptions.data.length >= 10,
      };
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return useInfiniteQuery(
    ['subscriptions', organizationId],
    fetchSubscriptions,
    {
      getNextPageParam: (lastPage) =>
        lastPage.hasNextPage ? lastPage.nextPage : undefined,
    }
  );
};

export default useSubscriptions;
