import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  paper: {
    maxWidth: '100%',
    maxHeight: '80vh',
    height: '80vh',
  },
  actionSpacing: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignContent: 'flex-end',
  },
  commentsContainer: {
    paddingLeft: '10px',
    alignContent: 'flex-start',
  },
  gridItem: {
    flexBasis: '100%',
  },
  leftContainer: {
    backgroundColor: '#EFF3FA',
    borderRadius: '8px',
    padding: '20px',
    marginTop: '10px',
  },
  topic: {
    display: 'flex',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '30px',
    lineHeight: '41px',
    color: '#001847',
  },
  cancelButton: {
    '&:hover': {
      backgroundColor: '#FFFFFF',
      color: '#FF5E5E',
    },
    fontWeight: 'bold',
    background: '#FFFFFF',
    border: '1px solid #FF5E5E',
    borderRadius: '4px',
    letterSpacing: '1px',
    color: '#FF5E5E',
    marginTop: '1%',
    marginLeft: '1%',
    marginBottom: '10px',
    marginRight: '20px',
    [theme.breakpoints.down('sm')]: {
      marginRight: '10px',
    },
  },
  updateButton: {
    '&:hover': {
      backgroundColor: '#0F5EF7',
      color: '#FFFFFF',
    },
    fontWeight: 'bold',
    background: '#0F5EF7',
    letterSpacing: '1px',
    borderRadius: '4px',
    color: '#FFFFFF',
    marginTop: '10px',
    marginBottom: '10px',
    marginLeft: 'auto',
    alignSelf: 'flex-end',
  },
  backArrow: {
    '&:hover': {
      backgroundColor: '#FFFFFF',
      color: '#0F5EF7',
    },
    background: '#FFFFFF',
    boxShadow: '0px 4px 22px rgba(0, 0, 0, 0.17)',
    borderRadius: '50%',
    color: '#0F5EF7',
    marginRight: '25px',
    padding: '11px',
    width: '40px',
    height: '40px',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  topicFont: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '30px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px',
    },
  },
  subTopic: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#001847',
  },
  textField: {
    backgroundColor: '#FFFFFF',
    borderRadius: '4px',
    margin: '0px',
  },
  creator: {
    '& .MuiOutlinedInput-inputMarginDense': {
      padding: '0 14px',
      height: '40px',
    },
  },
}));

export default useStyles;
