import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import LoaderSpinner from 'components/LoaderSpinner';

const useStyles = makeStyles((theme) => ({
  createButton: {
    '&:hover': {
      backgroundColor: '#0F5EF7',
      color: '#FFFFFF',
    },
    fontWeight: 'bold',
    background: '#0F5EF7',
    letterSpacing: '1px',
    borderRadius: '4px',
    color: '#FFFFFF',
    marginTop: '1%',
    marginLeft: '1%',
    marginBottom: '10px',
  },
}));
const loading = '';
const LoadingButton = ({
  id,
  children,
  onClickHandler,
  disableStatus,
  updatingStatus,
}) => {
  const classes = useStyles();
  return (
    <Button
      className={classes.createButton}
      id={id}
      variant="contained"
      startIcon={
        updatingStatus ? (
          <LoaderSpinner color="#808CA3" height={20} width={20} />
        ) : (
          ''
        )
      }
      disabled={disableStatus}
      onClick={onClickHandler}
    >
      {children}
    </Button>
  );
};

export default LoadingButton;
