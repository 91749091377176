import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  footer: {
    paddingLeft: theme.appContainer.paddingLeft,
    paddingRight: theme.appContainer.paddingLeft,
    backgroundColor: theme.palette.primary.main,
    boxShdow: '0px 4px 9px rgba(0, 0, 0, 0.2)',
    width: '100%',
    height: '60px',
    flexShrink: '1',
    display: 'flex',
    fontFamily: theme.typography.fontFamily,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '50px',
      paddingRight: '50px',
    },
  },
  leftText: {
    alignSelf: 'center',
    color: 'rgba(255, 255, 255, 1)',
    fontWeight: '400',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '19px',
    letterSpacing: '1px',
  },
  rightText: {
    alignSelf: 'center',
  },
  link: {
    textTransform: 'uppercase',
    textDecoration: 'none',
    color: 'rgba(181, 189, 233, 1)',
    paddingLeft: '35px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '5rem',
    },
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Grid container justifyContent="space-between">
        <Grid item classes={{ item: classes.leftText }}>
          <Grid container>
            <Grid
              item
            >{`\u00A9${new Date().getFullYear()} Randoli Inc. All Rights Reserved`}</Grid>
          </Grid>
        </Grid>
        <Grid item classes={{ item: classes.rightText }}>
          <Grid container>
            <Grid item>
              <a
                href="https://www.randoli.ca/policies/privacy-policy"
                className={classes.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>Privacy</span>
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
