import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    color: props.statusColor,
    fontFamily: 'Nunito',
    fontWeight: '600',
    fontStyle: 'normal',
    fontSize: '14px',
    textTransform: 'capitalize',
    lineHeight: '18px',
  }),
}));

const CreateDate = ({ date }) => {
  const classes = useStyles();

  return <span className={classes.root}>{date?.slice(0, 10)}</span>;
};

export default CreateDate;
