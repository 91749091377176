import { useQuery } from 'react-query';

import api from 'services/Api';
import TicketStatus from 'schema/ticket-status';
import { PATH_CSP_AGGREGATOR_SERVICE } from '../../constants';

const useTicketSummary = (organizationId) => {
  const fetchCounts = async () => {
    const titles = {
      [TicketStatus.OPENED.enumKey]: 'New Tickets',
      [TicketStatus.IN_REVIEW.enumKey]: 'In-Review Tickets',
      [TicketStatus.IN_PROGRESS.enumKey]: 'In-Progress Tickets',
      [TicketStatus.CLOSED.enumKey]: 'Resolved Tickets',
    };

    try {
      const response = await api.get(
        `${PATH_CSP_AGGREGATOR_SERVICE}/v1/tickets/summary?organization-id=${organizationId}`
      );

      const {
        data: { ticket },
      } = await response.json();

      return Object.keys(titles).map((key) => {
        return { count: ticket[key] || 0, title: titles[key] };
      });
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return useQuery('ticket/counts', fetchCounts, {
    refetchOnWindowFocus: false,
  });
};

export default useTicketSummary;
