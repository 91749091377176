import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';
import '../Tickets';
import {
  ticketFetchStart,
  ticketFetchSuccess,
  ticketFetchError,
} from 'state/tickets';
import { selectTicketTickets } from 'state/tickets/selectors';
import { selectProfile } from 'state/auth/selectors';
import Table from 'components/ReactTable';
import PriorityChip from 'components/PriorityChip';
import StatusText from 'components/StatusText';
import api from 'services/Api';
import { PATH_CSP_AGGREGATOR_SERVICE, PATH_IAM_SERVICE } from '../../constants';
import SearchBox from 'components/SearchBox';
import CustomFilter from 'components/CustomFilter';
import useTickets from 'hooks/services/useTickets';
import { selectUser, selectOrganization } from 'state/auth/selectors';
import CreateDate from 'components/CreatedDate';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import DatePicker from 'react-datepicker';
import '../../index.css';
import 'react-datepicker/dist/react-datepicker.css';
import { convert, getCurrentDateAsString } from 'utils/time';
import { FLOW } from '../../constants';
const useStyles = makeStyles((theme) => ({
  openTicket: {
    marginTop: '2%',
  },
  root: {
    flex: 1,
  },
  mobileView: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  rightCommandContainer: {
    alignItems: 'center',
  },
  personSelect: {
    maxWidth: '15rem',
  },
  entitlementSelect: {
    maxWidth: '25rem',
  },
  commandText: {
    fontWeight: 'bold',
  },
  selectPosition: {
    marginRight: '50px',
    [theme.breakpoints.down('sm')]: {
      margin: '0',
    },
  },
  sectionTitle: theme.pageSectionTitle,
  filters: {
    padding: '17px 0px 30px 0px',
  },
  searchBox: {
    flex: 0.5,
    marginRight: '50px',
    [theme.breakpoints.down('sm')]: {
      marginRight: '0',
    },
  },
  selectBox: {
    [theme.breakpoints.up('md')]: {
      flex: 1,
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '10px',
      flexWrap: 'wrap',
    },
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    marginRight: theme.spacing(1),
    paddingLeft: '20px',
    width: 200,
    height: 40,
    background: '#EFF3FA',
    borderRadius: '5px',
    border: '0px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0',
      width: '100%',
    },
  },
  truncate: {
    maxHeight: '1.5em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

const Tickets = () => {
  const classes = useStyles();

  const cachedTickets = useSelector(selectTicketTickets);
  const cachedAuth = useSelector(selectProfile);
  const dispatch = useDispatch();

  const [entitlement, setEntitlement] = useState([]);
  const [severity, setSeverity] = useState([]);
  const [status, setStatus] = useState([]);
  const [, setEntitlementId] = useState([]);
  const [assigneeId, setAssigneeId] = useState([]);

  const userId = useSelector(selectUser);
  const organizationId = useSelector(selectOrganization);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const defaultDate = '2022-01-01';

  let start = convert(startDate, defaultDate);
  let end = convert(endDate, defaultDate);

  if (end === defaultDate) {
    end = getCurrentDateAsString();
  }
  const [filter, setFilter] = useState({
    status: 'NOT CLOSED',
    userPriority: 'All',
  });

  let [flattenedTickets, setFlattenedTickets] = useState([]);
  const { data: myTickets } = useTickets({
    orgId: organizationId,
    userId: userId,
  });

  useEffect(() => {
    let reducedflattenedTickets =
      myTickets &&
      myTickets.pages.reduce((accum, curr) => {
        return [...accum, ...curr.tickets];
      }, []);

    if (reducedflattenedTickets) {
      setFlattenedTickets(reducedflattenedTickets);

      setAllTickets(reducedflattenedTickets);
    }
  }, [myTickets]);
  const [ticketId, setTicketId] = useState([]);
  const [allTicket, setAllTickets] = useState([]);

  const [ticketData, setTicketData] = useState(null);

  const [openTicketSearch, setOpenTicketSearch] = useState(false);

  const [refreshKey, setRefreshKey] = useState(0);

  const history = useHistory();
  const { path } = useRouteMatch();

  const handleCreateTicket = () => {
    history.push({
      state: {
        entitlement: entitlement,
        ticketId: ticketId,
        allTicket,
        flow: FLOW.CREATE,
      },
      pathname: `${path}/create`,
    });
  };

  const handleOpenTicketSearch = () => {
    setOpenTicketSearch(true);
  };

  const handleCloseTicketSearch = () => {
    setOpenTicketSearch(false);
  };

  const handleTableRowClick = (id, { original: ticketData }) => {
    history.push({
      state: {
        ticketData,
        assigneeId,
        entitlement,
        ticketId,
        allTicket,
        flow: FLOW.VIEW,
      },
      pathname: `${path}/${ticketData.entitlementId}/${ticketData.ticketId}`,
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Ticket No',
        Cell: ({ row }) =>
          `${
            row.original.ticketDisplayId
              ? row.original.ticketDisplayId
              : row.original.ticketId
          }`,
      },
      {
        Header: 'Title',
        accessor: 'title',
      },
      {
        Header: 'Created By',
        accessor: 'createdBy',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({
          cell: {
            row: { values },
          },
        }) => {
          return <StatusText priority={values.status} />;
        },
      },
      {
        Header: 'Priority',
        accessor: 'userPriority',
        Cell: ({
          cell: {
            row: { values },
          },
        }) => {
          return <PriorityChip priority={values.userPriority} />;
        },
      },
      {
        Header: 'Product or Service',
        accessor: 'entitlementName',
      },
      {
        Header: 'Created Date',
        accessor: 'createdAt',
        Cell: ({
          cell: {
            row: { values },
          },
        }) => {
          return <CreateDate date={values.createdAt} />;
        },
      },
      {
        Header: 'Last Modified Date',
        accessor: 'modifiedDate',
        Cell: ({
          cell: {
            row: { values },
          },
        }) => {
          return <CreateDate date={values.modifiedDate} />;
        },
      },
    ],
    []
  );

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const response = await api.get(
          `${PATH_CSP_AGGREGATOR_SERVICE}/v1/tickets?organization-id=${cachedAuth.attributes.orgId[0]}&size=50`
        );

        if (response.ok) {
          const {
            data: { tickets },
          } = await response.json();
          setTicketData(tickets);
          return tickets;
        }

        throw new Error(`Error fetching ${response.url}`);
      } catch (error) {
        return Promise.reject(error);
      }
    };

    const fetchEntitlements = async () => {
      try {
        const response = await api.get(
          `${PATH_CSP_AGGREGATOR_SERVICE}/v1/entitlements?organization-id=${cachedAuth.attributes.orgId[0]}`
        );

        if (response.ok) {
          const {
            data: { entitlements },
          } = await response.json();
          return entitlements;
        }

        throw new Error(`Error fetching ${response.url}`);
      } catch (error) {
        return Promise.reject(error);
      }
    };

    dispatch(ticketFetchStart());

    Promise.all([fetchTickets(), fetchEntitlements()])
      .then(([ticketData, entitlements]) => {
        if (ticketData) {
          let tickets = ticketData.map(
            ({
              ticketId,
              ticketDisplayId,
              title,
              entitlementId,
              entitlementName,
              createdUserId: owner,
              assigneeEmail,
              assignedTo,
              assigneeId,
              createdBy,
              description,
              status,
              userPriority: userPriority,
              modifiedDate,
              createdAt,
              watchers,
              attachments,
            }) => {
              return {
                ticketId,
                ticketDisplayId,
                title,
                entitlementId,
                entitlementName,
                owner,
                assignedTo,
                assigneeEmail,
                assigneeId,
                createdBy,
                description,
                status,
                userPriority,
                modifiedDate,
                createdAt,
                watchers,
                attachments,
              };
            }
          );
          const entitleArr = entitlements.map((entitle) => {
            return {
              name: entitle.title,
              value: entitle.entitlementId,
            };
          });

          const severityArr = tickets.map((t) => t.severity);
          const ticketIdArr = tickets.map((t) => t.ticketId);
          const statusArr = tickets.map((t) => t.status);
          const entitlementIdArr = tickets.map((t) => t.entitlementId);
          const ticketTitleArr = tickets.map((t) => t.title);
          setAllTickets(tickets);
          dispatch(ticketFetchSuccess({ tickets }));

          setSeverity([...new Set(severityArr)]);
          setTicketId(ticketIdArr);
          setStatus([...new Set(statusArr)]);
          setEntitlementId([...new Set(entitlementIdArr)]);
          setEntitlement(entitleArr);
        }
      })
      .catch((error) => {
        dispatch(ticketFetchError());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshKey]);

  const filterTickets = (event) => {
    const searchValue = event.target.value.toLowerCase();

    flattenedTickets = allTicket.filter((item) => {
      const formattedTicketId = item.ticketId.toString().padStart(6, '0');
      const normalizedTicketId = item.ticketId.toString().toLowerCase();
      return (
        item.title.toLowerCase().includes(searchValue) ||
        formattedTicketId === searchValue ||
        normalizedTicketId.includes(searchValue.trim()) ||
        item.ticketDisplayId.toLowerCase().includes(searchValue) ||
        normalizedTicketId.includes(searchValue)
      );
    });

    setFlattenedTickets(flattenedTickets);
  };

  const handlePriorityChange = (event) => {
    setFilter({ ...filter, userPriority: event.target.value });
  };

  const handleStatusChange = (event) => {
    setFilter({ ...filter, status: event.target.value });
  };
  const handleAsigneeChange = (event) => {
    setFilter({ ...filter, internalAssigneeEmail: event.target.value });
  };

  // To pass the return type correctly to the custom select
  const select = (arr) => {
    let count = 0;
    return arr.map((x) => {
      if (x.name && x.value) {
        return { id: count++, ...x };
      } else {
        return { id: count++, name: x };
      }
    });
  };
  const statusData = [
    {
      id: 1,
      name: 'OPENED',
    },
    {
      id: 2,
      name: 'IN_PROGRESS',
    },
    {
      id: 3,
      name: 'ON_HOLD',
    },
    {
      id: 4,
      name: 'IN_REVIEW',
    },
    {
      id: 5,
      name: 'REOPEN',
    },
    {
      id: 6,
      name: 'CLOSED',
    },
    {
      id: 7,
      name: 'NOT CLOSED',
    },
  ];

  const PriorityData = [
    {
      id: 1,
      name: 'LOW',
    },
    {
      id: 2,
      name: 'MEDIUM',
    },
    {
      id: 3,
      name: 'HIGH',
    },
    {
      id: 4,
      name: 'URGENT',
    },
  ];

  const matchesStatusFilter = (ticket, filter) => {
    if (filter.status === 'All') {
      return true;
    } else if (filter.status === 'NOT CLOSED') {
      return ticket.status.toUpperCase() !== 'CLOSED';
    } else {
      return ticket.status.toUpperCase() === filter.status.toUpperCase();
    }
  };

  const matchesUserPriorityFilter = (ticket, filter) => {
    return (
      filter.userPriority === 'All' ||
      ticket.userPriority.toUpperCase() === filter.userPriority.toUpperCase()
    );
  };

  const matchesDateRangeFilter = (ticket, start, end) => {
    const ticketDate = ticket.createdAt.slice(0, 10);
    return ticketDate >= start && ticketDate <= end;
  };

  const filteredTickets =
    cachedTickets &&
    flattenedTickets.filter((ticket) => {
      return (
        matchesStatusFilter(ticket, filter) &&
        matchesUserPriorityFilter(ticket, filter) &&
        matchesDateRangeFilter(ticket, start, end)
      );
    });

  return (
    <Grid container alignContent="flex-start">
      <Grid
        item
        container
        className={`${classes.filters} ${classes.mobileView}`}
        justifyContent="space-between"
      >
        <Grid item className={classes.searchBox}>
          <SearchBox placeholder="Search..." onchangefunc={filterTickets} />
        </Grid>

        <Grid item container spacing={3} className={classes.selectBox}>
          <Grid item xs={12} sm={6} md={3} className={classes.selectBox}>
            <CustomFilter
              placeholder="Select status"
              selectItems={statusData}
              handleChange={handleStatusChange}
              selectedValue={filter?.status}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.selectBox}>
            <CustomFilter
              placeholder="Select priority"
              selectItems={PriorityData}
              handleChange={handlePriorityChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.selectBox}>
            <DatePicker
              id="date"
              class="react-datepicker-popper"
              selectsRange={true}
              placeholderText="Select date range"
              startDate={startDate}
              endDate={endDate}
              className={classes.textField}
              onChange={(update) => {
                setDateRange(update);
              }}
              isClearable={true}
            />
          </Grid>

          <Grid item>
            <Button
              className={classes.selectPosition}
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleCreateTicket}
            >
              Create Ticket
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Table
            columns={columns}
            tableMaxHeight="calc(100vh - 240px)"
            data={filteredTickets || []}
            onClickTableRow={handleTableRowClick}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Tickets;
