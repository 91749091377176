import { Enumify } from 'enumify';

class SubscriptionStatus extends Enumify {
  static ACTIVE = new SubscriptionStatus();
  static PENDING_ACTIVATION = new SubscriptionStatus();
  static DEACTIVATED = new SubscriptionStatus();
  static PENDING_DEACTIVATION = new SubscriptionStatus();
  static _ = this.closeEnum();
}

export default SubscriptionStatus;
