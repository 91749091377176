import { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { AuthContext } from './auth-provider';
import { authStart, authError, authSuccess } from 'state/auth';
import { selectAuthenticated, selectRoles } from 'state/auth/selectors';

export const useAuth = () => {
  const { isInitialized, keycloak: kc } = useContext(AuthContext);
  const isAuthenticated = useSelector(selectAuthenticated);
  const userRoles = useSelector(selectRoles);
  const dispatch = useDispatch();

  const login = (options) => {
    kc && kc.login(options);
  };

  const logout = (options) => {
    kc && kc.logout(options);
  };

  const isAuthorized = (requiredRoles) => {
    const _requiredRoles = Array.isArray(requiredRoles)
      ? requiredRoles
      : [requiredRoles];

    return _requiredRoles.some((requiredRole) =>
      userRoles.includes(requiredRole)
    );
  };

  const handleAuthentication = async () => {
    if (kc) {
      dispatch(authStart());

      const {
        loadUserProfile,
        authenticated,
        realmAccess: { roles },
        tokenParsed: { groups },
      } = kc;

      loadUserProfile()
        .then((profile) => {
          dispatch(authSuccess({ authenticated, profile, roles, groups }));
        })
        .catch((err) => {
          dispatch(authError(err));
        });
    }
  };

  return {
    isInitialized,
    isAuthenticated,
    login,
    logout,
    handleAuthentication,
    isAuthorized,
  };
};
