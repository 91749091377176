import React from 'react';

import { default as MaterialAvatar } from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 30px 0 30px',
    flexWrap: 'nowrap',
  },
  avatar: {
    height: '45px',
    width: '45px',
    border: '1px solid rgba(229, 229, 229, 1)',
  },
  userNameRoot: {
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: '9px',
  },
  userName: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '19px',
  },
  userRole: {
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '18px',
    color: 'rgba(179, 204, 252, 1)',
  },
}));

const Avatar = ({ picture, userName, role, groupName }) => {
  const classes = useStyles();

  return (
    <Grid container classes={{ root: classes.root }}>
      <Grid item>
        <MaterialAvatar alt={userName} classes={{ root: classes.avatar }} />
      </Grid>
      <Grid container item classes={{ root: classes.userNameRoot }}>
        <Grid item>
          <span className={classes.userName}>{userName}</span>
        </Grid>
        <Grid item>
          <span className={classes.userRole}>{groupName}</span>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Avatar;
