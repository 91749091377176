import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';

import ListTickets from './list-tickets';
import ManageTicket from './manage-ticket';

const Tickets = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={ListTickets} />
      <Route path={`${path}/create`} component={ManageTicket} />
      <Route
        path={`${path}/:entitlementId/:ticketId`}
        component={ManageTicket}
      />
      <Redirect to={path} />
    </Switch>
  );
};

export default Tickets;
