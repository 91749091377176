import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import AddAPhotoIcon from '@material-ui/icons/AddAPhotoOutlined';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/PersonOutlineOutlined';
import BackIcon from 'components/BackIcon';

import { useAuth } from 'hooks/useAuth';
import useUpdateUserProfile from 'hooks/services/useUpdateUserProfile';
import useUpdateUserPassword from 'hooks/services/useUpdateUserPassword';
import {
  selectProfile,
  selectUserName,
  selectUser,
  selectGroupId,
  selectState,
} from 'state/auth/selectors';
import { selectUserRole } from 'state/userRole/selector';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  heading: {
    paddingTop: '29px',
  },
  headingTitle: theme.pageHeading,
  section: {
    paddingTop: '40px',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  container: {
    flexDirection: 'row',
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  image: {
    paddingTop: '62px',
  },
  name: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '25px',
  },
  button: {
    backgroundColor: '#0F5EF7',
    '&:hover': {
      backgroundColor: '#0F5EF7',
    },
    borderRadius: '4px',
    width: '166px',
    color: 'white',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    textTransform: 'uppercase',
  },
  backButton: {
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
    padding: 0,
  },
  text: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '19px',
    color: '#001847',
    paddingBottom: '10px',
  },
  input: {
    background: '#EFF3FA',
    borderRadius: '4px',
    height: '40px',
    width: '400px',
    paddingLeft: '20px',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    color: '#001847',
  },
  box: {
    padding: '37px 0 25px 0',
  },
  boxView: {
    paddingBottom: '40px',
  },
  icon: {
    fontSize: '80px',
  },
  avatar: {
    height: '140px',
    width: '140px',
    backgroundColor: '#B5BDE9',
  },
  badge: {
    paddingBottom: '12px',
  },
}));

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 42,
    height: 42,
    backgroundColor: 'white',
    boxShadow: '0px 1px 8px rgba(20, 46, 110, 0.1)',
  },
}))(Avatar);

const UserProfile = () => {
  // const [t] = useTranslation('users');
  const classes = useStyles();
  const history = useHistory();
  const userProfile = useSelector(selectProfile);
  const [firstName, setFirstName] = useState(
    userProfile ? userProfile.firstName : ''
  );
  const [lastName, setLastName] = useState(
    userProfile ? userProfile.lastName : ''
  );
  const [newPassword, setNewPassword] = useState();
  const [currentPassword, setCurrentPassword] = useState();
  const userName = useSelector(selectUserName);
  const groupData = useSelector(selectUserRole);
  const { logout, handleAuthentication } = useAuth();
  const { register, errors } = useForm({
    mode: 'onChange',
  });
  const { enqueueSnackbar } = useSnackbar();

  const userId = useSelector(selectUser);
  const userGroupId = useSelector(selectGroupId);

  const userState = useSelector(selectState);
  const { mutateAsync: userProfileUpdater, isError: isProfileUpdateError } =
    useUpdateUserProfile(userId);
  const { mutateAsync: userPassswordUpdater, isError: isPassUpdateError } =
    useUpdateUserPassword(userId);

  const handleFirstName = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastName = (e) => {
    setLastName(e.target.value);
  };

  const handleCurrentPassword = (e) => {
    setCurrentPassword(e.target.value);
  };

  const handleNewPassword = (e) => {
    setNewPassword(e.target.value);
  };

  const handleClose = () => {
    history.goBack();
  };

  const handleSave = async () => {
    if (
      firstName &&
      lastName &&
      (firstName !== userProfile.firstName || lastName !== userProfile.lastName)
    ) {
      const data = {
        groupId: userGroupId,
        state: userState,
        firstName,
        lastName,
        termsAccepted: 'true',
      };
      try {
        await userProfileUpdater(data);
        if (!isProfileUpdateError) {
          handleAuthentication();
          enqueueSnackbar('User profile updated successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
      } catch (e) {
        enqueueSnackbar('User profile updated failed', {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    }
    if (currentPassword && newPassword) {
      const data = {
        currentPassword,
        newPassword,
      };
      try {
        await userPassswordUpdater(data);
        if (!isPassUpdateError) {
          logout();
        }
      } catch (e) {
        enqueueSnackbar('Password changed failed', {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    }
  };

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item container>
          <span className={classes.headingTitle}>
            <IconButton
              id="btn-back"
              onClick={handleClose}
              className={classes.backButton}
            >
              <BackIcon />
            </IconButton>
            User Profile
          </span>
        </Grid>
        <Grid item>
          <Badge className={classes.badge}>
            <Avatar className={classes.avatar}>
              <PersonIcon className={classes.icon} />
            </Avatar>
          </Badge>
        </Grid>
        <Grid item>
          <span className={classes.name}>{userName.fullName}</span>
        </Grid>
        <Grid item className={classes.box}>
          <Grid item container spacing={3}>
            <Grid item>
              <Typography variant="body2" className={classes.text}>
                First Name
              </Typography>
              <FormControl fullWidth>
                <Input
                  error={errors.firstName}
                  inputRef={register}
                  name="firstName"
                  value={firstName}
                  onChange={(e) => {
                    handleFirstName(e);
                  }}
                  className={classes.input}
                  disableUnderline
                />
              </FormControl>
            </Grid>
            <Grid item>
              <Typography variant="body2" className={classes.text}>
                Last Name
              </Typography>
              <FormControl fullWidth>
                <Input
                  className={classes.input}
                  error={errors.lastName}
                  inputRef={register}
                  name="lastName"
                  value={lastName}
                  onChange={(e) => {
                    handleLastName(e);
                  }}
                  disableUnderline
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.boxView}>
          <Grid item container spacing={3}>
            <Grid item>
              <Typography variant="body2" className={classes.text}>
                Current Password
              </Typography>
              <FormControl fullWidth>
                <Input
                  className={classes.input}
                  error={errors.currentPassword}
                  inputRef={register}
                  type="password"
                  name="currentPassword"
                  value={currentPassword}
                  placeholder="Enter Password"
                  onChange={(e) => {
                    handleCurrentPassword(e);
                  }}
                  disableUnderline
                />
              </FormControl>
            </Grid>
            <Grid item>
              <Typography variant="body2" className={classes.text}>
                New Password
              </Typography>
              <FormControl fullWidth>
                <Input
                  className={classes.input}
                  error={errors.newPassword}
                  inputRef={register}
                  type="password"
                  name="newPassword"
                  value={newPassword}
                  placeholder="Enter New Password"
                  onChange={(e) => {
                    handleNewPassword(e);
                  }}
                  disableUnderline
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{ paddingBottom: '20px' }}>
          <Button
            variant="contained"
            className={classes.button}
            onClick={handleSave}
            disabled={
              firstName === userProfile.firstName &&
              lastName === userProfile.lastName
                ? !currentPassword || !newPassword
                  ? true
                  : false
                : !firstName || !lastName
                ? true
                : false
            }
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default UserProfile;
