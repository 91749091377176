import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/styles';
import './styles.css';

import PrivateRoute from 'services/PrivateRoute';
import AuthCallback from 'services/AuthCallback';
import Dashboard from 'pages/Dashboard';
import Tickets from 'pages/Tickets';
import Entitlements from 'pages/Entitlements';
import UnAuthorized from 'services/UnAuthorized';
import SignIn from 'services/SignIn';
import UserProfile from 'pages/UserProfile';
import { selectRoles } from 'state/auth/selectors';
import {
  ROUTE_DASHBOARD,
  ROUTE_ENTITLEMENTS,
  ROUTE_TICKETS,
  ROUTE_SUBSCRIPTIONS,
  ROUTE_AUTH_CALLBACK,
  ROUTE_UN_AUTHORIZED,
  ROUTE_SIGN_IN,
  ROUTE_USERPROFILE,
} from '../../constants';
import Subscriptions from 'pages/Subscriptions';

const useStyles = makeStyles((theme) => ({
  content: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
}));

const AppRouter = () => {
  const classes = useStyles();
  const roles = useSelector(selectRoles);
  const initialPath = sessionStorage.getItem('initialPath');

  let initialPage = initialPath;

  if (initialPath == "/") {
    if (roles.includes('csp_view_dashboard')) {
      initialPage = "/dashboard";
    } else {
      initialPage = "/tickets";
    }
  }

  return (
    <>
      <Helmet title="Randoli Inc. | Customer Support Portal" />
      <div className={classes.content}>
        <Switch>
          <Route exact path={ROUTE_AUTH_CALLBACK} component={AuthCallback} />
          <Route exact path={ROUTE_SIGN_IN} component={SignIn} />
          <Route exact path={ROUTE_UN_AUTHORIZED} component={UnAuthorized} />
          <PrivateRoute
            exact
            path={ROUTE_DASHBOARD}
            component={Dashboard}
            requiredRoles={['csp_view_dashboard']}
          />
          <PrivateRoute
            path={ROUTE_TICKETS}
            component={Tickets}
            requiredRoles={['csp_manage_tickets', 'csp_user']}
          />
          <PrivateRoute
            path={ROUTE_SUBSCRIPTIONS}
            component={Subscriptions}
            requiredRoles={['csp_manage_subscriptions']}
          />
          <PrivateRoute
            exact
            path={ROUTE_ENTITLEMENTS}
            component={Entitlements}
            requiredRoles={['csp_manage_entitlements']}
          />
          <PrivateRoute
            exact
            path={ROUTE_USERPROFILE}
            component={UserProfile}
            requiredRoles={['csp_user']}
          />
          <Redirect to={initialPage} />
        </Switch>
      </div>
      {/* <ReactQueryDevtools initialIsOpen /> */}
    </>
  );
};

export default AppRouter;
