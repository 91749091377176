import { useInfiniteQuery } from 'react-query';

import api from 'services/Api';
const useEntitlements = (cachedAuth) => {
  const fetchEntitlements = async () => {
    try {
      const response = await api.get(
        `csp/aggregator/v1/entitlements?organization-id=${cachedAuth.attributes.orgId[0]}`
      );
      const {
        data: { entitlements },
      } = await response.json();
      return entitlements;
    } catch (e) {
      return Promise.reject(e);
    }
  };
  return useInfiniteQuery(['entitlements'], fetchEntitlements, {
    refetchOnWindowFocus: false,
    getFetchMore: (lastGroup) => lastGroup.meta.nextPage,
  });
};
export default useEntitlements;
