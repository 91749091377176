import { css } from '@emotion/react';

const styles = () => {
  const container = css`
    display: block;
    flex-direction: column;
  `;

  const header = css`
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #001847;
    margin-bottom: 10px;
  `;

  const optionBar = css`
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
  `;

  const addButton = css`
    min-width: 260px;
    background-color: #001847;
    color: white;
    &:hover {
      background-color: rgb(0, 16, 49);
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
        0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    }
  `;

  const status = css`
    max-width: 300px !important;
  `;

  const buttonwrap = css`
    display: flex;
    justify-content: right;
  `;

  const dialogText = css`
    color: #001847;
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    line-height: 30px;
    margin: 10px 10px 0px 10px;
    font-size: 22px;
  `;

  const loading = css`
    display: flex;
    flex-direction: column;
    justify-content: ceneter;
    align-items: center;
    padding-bottom: 25px;
  `;

  const centerbox = css`
    display: flex;
    flex-direction: column;
    justify-content: ceneter;
    align-items: center;
  `;

  const leaveButton = css`
    padding: 0;
    width: 155px;
    height: 40px;
    color: #ffff;
    background-color: #ff7c7c;
    border-radius: 4px;
    margin: 6px 20px 25px 20px;
    letter-spacing: 0.5px;
    &:hover {
      background-color: #ff7c7c !important;
      border: 2px solid #626799;
    }
  `;

  return {
    container,
    header,
    optionBar,
    addButton,
    status,
    buttonwrap,
    dialogText,
    loading,
    centerbox,
    leaveButton,
  };
};

export default styles;
