import React from 'react';
import { CirclesWithBar } from 'react-loader-spinner';

const LoaderSpinner = ({ type, color, height, width, text }) => {
  return (
    <>
      <CirclesWithBar type={type} color={color} height={height} width={width} />
      {text && <h2>{text}</h2>}
    </>
  );
};

export default LoaderSpinner;
