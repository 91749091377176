import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const AlertDialog = ({
  open,
  handleClose,
  handleOk,
  title,
  children,
  buttonConfirmText,
  buttonCancelText,
}) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        <DialogContentText>{children}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOk} color="primary">
          {buttonConfirmText}
        </Button>
        <Button onClick={handleClose} color="primary" autoFocus>
          {buttonCancelText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
