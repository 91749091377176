import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import TicketStatus from 'schema/ticket-status';

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    color: props.statusColor,
    fontFamily: 'Nunito',
    fontWeight: '600',
    fontStyle: 'normal',
    fontSize: '14px',
    textTransform: 'capitalize',
    lineHeight: '18px',
  }),
}));

const statusMap = {
  [TicketStatus.OPENED.enumKey]: {
    statusColor: 'rgba(65, 152, 253, 1)',
  },
  [TicketStatus.IN_PROGRESS.enumKey]: {
    statusColor: 'rgba(236, 173, 10, 1)',
  },
  [TicketStatus.IN_REVIEW.enumKey]: {
    statusColor: 'rgba( 0,188,23,1)',
  },
  [TicketStatus.ON_HOLD.enumKey]: {
    statusColor: 'rgba(252, 45, 45, 1)',
  },
  // [TicketStatus.COMPLETED.enumKey]: {
  //   statusColor: 'rgba( 0,188,139,1)',
  // },
  [TicketStatus.CLOSED.enumKey]: {
    statusColor: 'rgba( 0,80,139,1)',
  },
};

const StatusText = ({ priority }) => {
  const classes = useStyles(statusMap[priority]);

  return <span className={classes.root}>{priority}</span>;
};

export default StatusText;
