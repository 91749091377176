import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  isUserUpdating: false,
  isFetching: false,
  users: [],
  groups: [],
};

const usersSlice = createSlice({
  name: 'users',
  initialState: INITIAL_STATE,
  reducers: {
    usersFetchStart(state, action) {
      state.isFetching = true;
      state.users = [];
    },
    usersFetchSuccess(state, { payload: { users } }) {
      state.isFetching = false;
      state.users = users;
    },
    usersFetchError(state, action) {
      state.isFetching = false;
      state.users = [];
    },
    groupsFetchStart(state, action) {
      state.isFetching = true;
      state.groups = [];
    },
    groupsFetchSuccess(state, { payload: { groups } }) {
      state.isFetching = false;
      state.groups = groups;
    },
    groupsFetchError(state, action) {
      state.isFetching = false;
      state.groups = [];
    },
    userUpdateStart(state, action) {
      state.isUserUpdating = true;
    },
    userUpdateSuccess(state, { payload: { userId, status } }) {
      state.isUserUpdating = false;
      state.users.forEach((user) => {
        if (user.id === userId) {
          user.status = status;
        }
      });
    },
    userUpdateError(state, action) {
      state.isUserUpdating = false;
    },
  },
});

export const { name, actions, reducer } = usersSlice;
export const {
  usersFetchStart,
  usersFetchSuccess,
  usersFetchError,
  groupsFetchStart,
  groupsFetchSuccess,
  groupsFetchError,
  userUpdateSuccess,
  userUpdateStart,
  userUpdateError,
} = actions;
export default reducer;
