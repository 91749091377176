import { createSelector } from 'reselect';

export const selectEntitlements = (state) => state.entitlements;

export const selectCurrentEntitlements = createSelector(
  [selectEntitlements],
  (entitlements) => {
    return entitlements.entitlements;
  }
);
