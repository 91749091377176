import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: '8px',
    height: '100px',
    background: 'white',
    paddingLeft: '22px',
  },
  score: {
    fontFamily: 'Lato',
    fontWeight: '700',
    fontStyle: 'normal',
    fontSize: '40px',
    lineHeight: '40px',
    color: 'rgba(15, 94, 247, 1)',
  },
  title: {
    fontFamily: 'Nunito',
    fontWeight: '600',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '22px',
    color: 'rgba(128, 140, 163, 1)',
  },
}));

const ScoreCard = ({ count, title, isMobile }) => {
  const classes = useStyles();

  return (
    <div
      className={classes.cardRoot}
      style={{ padding: isMobile ? '10px' : '10' }}
    >
      <span className={classes.score}>{count}</span>
      <span className={classes.title}>{title}</span>
    </div>
  );
};

export default ScoreCard;
