import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: INITIAL_STATE,
  reducers: {},
});

export const { name, actions, reducer } = dashboardSlice;
export default reducer;
