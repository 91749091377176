import React, { useState } from 'react';
import { useTable } from 'react-table';

import MaTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';

import useHover from 'hooks/useHover';

const useStyles = makeStyles((theme) => ({
  root: {
    borderSpacing: '0 8px',
  },
  container: {
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 280px)',
    flex: 1,
    marginBottom: '40px',
  },
  head: {
    top: 0,
    zIndex: 10,
    position: 'sticky',
    borderBottom: '0px',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '22px',
    color: '#808CA3',
  },
  row: {
    boxShadow: '0px 1px 8px rgba(20, 46, 110, 0.1)',
    borderRadius: '10px',
  },
  cell: {
    borderBottom: '0px',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '19px',
    color: '#001847',
  },
}));

const Table = ({
  hiddenColumns,
  columns,
  data,
  onClickTableRow,
  children,
  ...props
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
      onClickTableRow,
      initialState: { hiddenColumns: [hiddenColumns] },
    });
  const classes = useStyles(props);
  const [selectedId, setSelectedId] = useState(false);

  const handleRowClick = (idx, rowData) => {
    setSelectedId(idx);
    onClickTableRow && onClickTableRow(idx, rowData);
  };

  return (
    <div className={classes.container}>
      <MaTable
        {...getTableProps()}
        stickyHeader
        classes={{ root: classes.root }}
      >
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell
                  {...column.getHeaderProps()}
                  className={classes.head}
                >
                  {column.render('Header')}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map(
            (row, index) =>
              prepareRow(row) || (
                <Row
                  index={index}
                  row={row}
                  {...row.getRowProps()}
                  selectedId={selectedId}
                  handleRowClick={handleRowClick}
                />
              )
          )}
        </TableBody>
      </MaTable>
    </div>
  );
};

const Row = ({ row, index, selectedId, handleRowClick }) => {
  const classes = useStyles();
  const [hoverRef, isHovered] = useHover();

  return (
    <TableRow
      ref={hoverRef}
      classes={{ root: classes.row }}
      key={index}
      hover
      selected={selectedId === index}
      {...row.getRowProps()}
      onClick={() =>
        handleRowClick(index, {
          state: row.state,
          original: row.original,
          index: row.index,
          values: row.values,
        })
      }
    >
      {row.cells.map((cell) => {
        return (
          <TableCell
            component="td"
            scope="row"
            {...cell.getCellProps()}
            classes={{ root: classes.cell }}
          >
            {cell.render('Cell', { isHovered })}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default Table;
