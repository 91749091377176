import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';

import LeaderBoard from 'components/LeaderBoard';
import TicketActivity from 'components/TicketActivity';
import MyTickets from 'components/MyTickets';
import Spinner from 'components/LoaderSpinner/Spinner';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom';

import { FLOW } from '../../constants';
import { selectUserName, selectOrganization } from 'state/auth/selectors';
import useTicketSummary from 'hooks/services/useTicketSummary';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  heading: {
    paddingTop: '29px',
  },
  headingTitle: theme.pageHeading,
  sectionTicketsActivity: {
    paddingTop: '40px',
    paddingLeft: '10px',
  },
  sectionMyTickets: {
    paddingTop: '10px',
    paddingLeft: '10px',
  },
  spinner: {
    width: '100%',
    height: '100%',
  },
}));

const Dashboard = () => {
  const [ticketId, setTicketId] = useState([]);
  const [allTicket, setAllTickets] = useState([]);
  const [entitlement, setEntitlement] = useState([]);

  const history = useHistory();

  const classes = useStyles();
  const userName = useSelector(selectUserName);
  const organizationId = useSelector(selectOrganization);

  const handleCreateTicket = () => {
    history.push({
      state: {
        entitlement: entitlement,
        ticketId: ticketId,
        allTicket,
        flow: FLOW.CREATE,
      },
      pathname: `/tickets/create`,
    });
  };

  const { isLoading: isLoadingSummary, data: counts } =
    useTicketSummary(organizationId);

  if (isLoadingSummary) {
    return (
      <>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.spinner}
        >
          <Spinner color="#00BFFF" height={60} width={60} />
        </Grid>
      </>
    );
  }

  return (
    <Grid container classes={{ container: classes.gridContainer }}>
      <Grid item container justifyContent="space-between">
        <Grid item>
          <span className={classes.headingTitle}>Dashboard</span>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleCreateTicket}
          >
            Create Ticket
          </Button>
        </Grid>
      </Grid>

      <Grid item className={classes.heading}>
        <LeaderBoard firstName={userName.firstName} counts={counts} />
      </Grid>
      <Grid item className={classes.sectionTicketsActivity}>
        <TicketActivity />
      </Grid>
      <Grid item className={classes.sectionMyTickets}>
        <MyTickets />
      </Grid>
    </Grid>
  );
};

export default Dashboard;
