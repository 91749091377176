import React from 'react';

import Grid from '@material-ui/core/Grid';

import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';
import Spinner from 'components/LoaderSpinner/Spinner';

import TicketCard from 'components/TicketCard';
import TicketStatus from 'schema/ticket-status';

const ListItem = ({ index, style, data }) => {
  const { ticketId, entitlementId, title, userPriority, ticketDisplayId } =
    data[index];

  return (
    <TicketCard
      style={style}
      key={`${ticketId}${entitlementId}`}
      id={ticketDisplayId ? ticketDisplayId : ticketId}
      title={title}
      priority={userPriority}
      ticketData={data[index]}
    />
  );
};

const TicketList = ({
  tickets,
  type,
  isNextPageLoading,
  hasNextPage,
  loadMore,
}) => {
  if (tickets === undefined) {
    return (
      <>
        <Grid
          container
          justifyContent="center"
          style={{
            width: '100%',
            height: '100%',
            marginTop: '50px',
          }}
        >
          <Spinner color="#00BFFF" height={60} width={60} />
        </Grid>
      </>
    );
  }

  const flattenedTickets =
    tickets &&
    tickets.pages.reduce((accum, curr) => {
      return [...accum, ...curr.tickets];
    }, []);

  let filteredTickets = flattenedTickets;

  if (type === TicketStatus.OPENED.enumKey) {
    filteredTickets = filteredTickets.filter(
      (ticket) => ticket.status === TicketStatus.OPENED.enumKey
    );
  }

  if (type === TicketStatus.OPENED.enumKey && filteredTickets.length === 0) {
    return (
      <div style={{ paddingTop: '50px' }}>
        <span
          style={{ paddingLeft: '50px', fontWeight: 'bold', fontSize: '15px' }}
        >
          No New Tickets
        </span>
      </div>
    );
  }

  if (
    type === TicketStatus.IN_PROGRESS.enumKey &&
    filteredTickets.length === 0
  ) {
    return (
      <div style={{ paddingTop: '50px' }}>
        <span
          style={{ paddingLeft: '50px', fontWeight: 'bold', fontSize: '15px' }}
        >
          No Tickets In Progress
        </span>
      </div>
    );
  }
  if (type === TicketStatus.CLOSED.enumKey && filteredTickets.length === 0) {
    return (
      <div style={{ paddingTop: '50px' }}>
        <span
          style={{ paddingLeft: '50px', fontWeight: 'bold', fontSize: '15px' }}
        >
          No Resolved Tickets
        </span>
      </div>
    );
  }

  const loadMoreItems = isNextPageLoading ? () => {} : loadMore;

  const isItemLoaded = (index) =>
    !hasNextPage || index < filteredTickets.length;

  return (
    <AutoSizer>
      {({ height, width }) => (
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={100}
          loadMoreItems={loadMoreItems}
        >
          {({ onItemsRendered, ref }) => (
            <List
              height={height}
              width={width}
              itemCount={filteredTickets.length}
              itemSize={88}
              itemData={filteredTickets}
              overscanCount={4}
              onItemsRendered={onItemsRendered}
              ref={ref}
            >
              {ListItem}
            </List>
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  );
};

export default TicketList;
