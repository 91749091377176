import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import SubscriptionStatus from 'schema/subscription-status';

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    color: props.statusColor,
    fontFamily: 'Nunito',
    fontWeight: '600',
    fontStyle: 'normal',
    fontSize: '14px',
    textTransform: 'capitalize',
    lineHeight: '18px',
  }),
}));

const statusMap = {
  [SubscriptionStatus.PENDING_ACTIVATION.enumKey]: {
    statusColor: 'rgba(65, 152, 253, 1)',
  },
  [SubscriptionStatus.ACTIVE.enumKey]: {
    statusColor: 'rgba(0,188,23,1)',
  },
  [SubscriptionStatus.DEACTIVATED.enumKey]: {
    statusColor: 'rgba(252, 45, 45, 1)',
  },
  [SubscriptionStatus.PENDING_DEACTIVATION.enumKey]: {
    statusColor: 'rgba( 0,80,139,1)',
  },
};

const SubscriptionStatusText = ({ priority }) => {
  const classes = useStyles(statusMap[priority]);

  return <span className={classes.root}>{priority}</span>;
};

export default SubscriptionStatusText;
