import { Enumify } from 'enumify';

class Priority extends Enumify {
  static HIGH = new Priority({
    get displayValue() {
      return 'High';
    },
  });
  static MEDIUM = new Priority({
    get displayValue() {
      return 'Medium';
    },
  });
  static LOW = new Priority({
    get displayValue() {
      return 'Low';
    },
  });
  static URGENT = new Priority({
    get displayValue() {
      return 'Urgent';
    },
  });
  static CRITICAL = new Priority({
    get displayValue() {
      return 'Critical';
    },
  });
  static _ = this.closeEnum();
}

export default Priority;
