import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { ErrorBoundary } from 'react-error-boundary';

import { useAuth } from 'hooks/useAuth';
import Header from 'components/Header';
import Footer from 'components/Footer';
import ErrorFallback from 'components/ErrorFallback';
import { ROUTE_UN_AUTHORIZED, ROUTE_SIGN_IN } from '../../constants';

const useStyles = makeStyles((theme) => ({
  content: {
    flex: '1',
    display: 'flex',
    overflow: 'auto',
    padding: theme.container.padding,
  },
}));

const PrivateRoute = ({
  component: Component,
  path,
  exact = false,
  requiredRoles,
  ...rest
}) => {
  const classes = useStyles();
  const { isAuthenticated, isAuthorized } = useAuth();
  const userHasRequiredRole = isAuthorized(requiredRoles);

  // console.log('IsAuthenticated', isAuthenticated);
  // console.log('IsAuthorized for Roles', isAuthorized(requiredRoles));
  // console.log('RequiredRoles', requiredRoles);

  return (
    <Route
      path={path}
      exact={exact}
      {...rest}
      render={(props) =>
        isAuthenticated && userHasRequiredRole ? (
          <>
            <Header />
            <div className={classes.content}>
              <ErrorBoundary
                FallbackComponent={ErrorFallback}
                resetKeys={[path]}
              >
                <Component {...props} />
              </ErrorBoundary>
            </div>
            <Footer />
          </>
        ) : (
          <Redirect
            to={{
              pathname: userHasRequiredRole
                ? ROUTE_SIGN_IN
                : ROUTE_UN_AUTHORIZED,
              state: {
                requestedPath: path,
              },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
