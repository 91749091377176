import { createTheme } from '@material-ui/core/styles';

const randoliWhite = '#FCFCFC';
const randoliBlue = '#001847';

const Default = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    background: {
      default: `${randoliWhite}`,
    },
    common: {
      blue: `${randoliBlue}`,
    },
    primary: {
      main: `${randoliBlue}`,
    },
  },
  typography: {
    fontFamily: ['Nunito', 'Lato', 'sans-serif', 'Comfortaa', 'cursive'].join(
      ','
    ),
  },
  appContainer: {
    paddingLeft: '51px',
    paddingRight: '51px',
    '@media (max-width:600px)': {
      paddingLeft: '20px',
      paddingRight: '20px',
    },
  },
  container: {
    padding: '35px 30px 35px 30px',
    '@media (max-width:600px)': {
      padding: '20px 15px 20px 15px',
    },
  },
  mixins: {
    toolbar: {
      minHeight: '70px',
    },
  },
  pageHeading: {
    fontWeight: '600',
    fontStyle: 'normal',
    fontSize: '30px',
    lineHeight: '41px',
    '@media (max-width:600px)': {
      fontSize: '24px',
      lineHeight: '34px',
    },
  },
  pageSectionTitle: {
    fontWeight: '700',
    fontStyle: 'normal',
    fontSize: '22px',
    lineHeight: '30px',
    color: 'rgba(0, 24, 71, 1)',
    '@media (max-width:600px)': {
      fontSize: '18px',
      lineHeight: '24px',
    },
  },
});

export default Default;
