import React from 'react';
import { Provider } from 'react-redux';
import { Provider as FetchProvider } from 'use-http';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { QueryClient, QueryClientProvider } from 'react-query';
import { SnackbarProvider } from 'notistack';
import { AuthProvider } from 'hooks/useAuth';

import AppRouter from 'components/AppRouter';
import Default from 'themes/default';
import StoreFn from 'state';
import { ROUTE_AUTH_CALLBACK } from '../../constants';

const initConfig = {
  onLoad: 'login-required',
  promiseType: 'native',
  redirectUri: `${process.env.REACT_APP_CSP_DOMAIN}${process.env.PUBLIC_URL}${ROUTE_AUTH_CALLBACK}`,
};

const options = {
  cachePolicy: 'no-cache',
};

const queryCache = new QueryCache();

const App = () => {
  const handleEvent = (event) => {
    // console.log('event fired', event);
  };
  const queryClient = new QueryClient();

  const Store = StoreFn();

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider initConfig={initConfig} onEvent={handleEvent}>
        <Provider store={Store}>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <BrowserRouter basename={process.env.PUBLIC_URL}>
              <ThemeProvider theme={Default}>
                <CssBaseline />
                <FetchProvider
                  url={process.env.REACT_APP_CSP_API_GATEWAY_URL}
                  options={options}
                >
                  <AppRouter />
                </FetchProvider>
              </ThemeProvider>
            </BrowserRouter>
          </SnackbarProvider>
        </Provider>
      </AuthProvider>
    </QueryClientProvider>
  );
};

export default App;
