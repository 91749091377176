import { formatDistanceToNow } from 'date-fns';
import { enUS } from 'date-fns/locale';

export const convertToDifferenceFromNow = (time) => {
  if (time) {
    const convertedDate = new Date(time);

    const timeDifference = formatDistanceToNow(convertedDate, {
      addSuffix: true,
      locale: enUS,
    });

    // example input: 2024-01-19T14:08:37.211538709Z
    // example output: about 6 hours ago
    return timeDifference;
  }
};

export const convertToLocalTimestamp = (time) => {
  if (time) {
    const convertedDate = new Date(time);
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const date = `${convertedDate.getFullYear()}-${
      (convertedDate.getMonth() + 1 < 10 ? '0' : '') +
      (convertedDate.getMonth() + 1)
    }-${
      (convertedDate.getDate() < 10 ? '0' : '') + convertedDate.getDate()
    } ${convertedDate.toLocaleTimeString()}
      ${timezone ? `(${timezone})` : ''}`;

    // example output: 2024-04-01 2:09:23 PM (America/Recife)
    return date;
  }
};

export const convert = (str, defaultDate) => {
  const formattedDate = str
    ? new Date(str).toISOString().split('T')[0]
    : defaultDate;

  return formattedDate;
};

export const getCurrentDateAsString = () => {
  const today = new Date();
  const date =
    today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
  return date;
};
  