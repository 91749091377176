import { useMutation, useQueryClient } from 'react-query';

import api from 'services/Api';

const useUpdateStatus = (ticketData, value, event) => {
  const QueryClient = useQueryClient();
  let ticketEvent;
  if (value > 0) {
    ticketEvent = event.find((i) => i.comboValue === value).value;
  } else {
    ticketEvent = value;
  }

  const url = `csp/ticket/v1/tickets/${ticketData.entitlementId}/${ticketData.ticketId}/status?ticket-event=${ticketEvent}`;

  return useMutation(
    (obj) => api.patch(url, JSON.stringify(obj)).then((x) => x.json()),
    {
      onSuccess: async () => {
        QueryClient.invalidateQueries();
      },
    },
    {
      onError: async () => {
        console.log('error');
      },
    }
  );
};

export default useUpdateStatus;
