import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  isFetching: false,
  tickets: [],
};

const ticketsSlice = createSlice({
  name: 'tickets',
  initialState: INITIAL_STATE,
  reducers: {
    ticketFetchStart(state, action) {
      state.isFetching = true;
      state.tickets = [];
    },
    ticketFetchSuccess(state, { payload: { tickets } }) {
      state.isFetching = false;
      state.tickets = tickets;
    },
    ticketFetchError(state, action) {
      state.isFetching = false;
      state.tickets = [];
    },
  },
});

export const { name, actions, reducer } = ticketsSlice;
export const {
  ticketFetchStart,
  ticketFetchSuccess,
  ticketFetchError,
} = actions;
export default reducer;
