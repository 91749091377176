import React, { useEffect, useState } from 'react';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  select: {
    background: '#EFF3FA',
    borderRadius: '4px',
    height: '30px',
    padding: '10px 0 0 20px',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#808CA3',
  },
}));

const CustomFilter = ({
  disableUnderline = true,
  placeholder,
  selectItems,
  handleChange,
  selectedValue = '',
}) => {
  const classes = useStyles();
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(selectedValue);
  }, [selectedValue]);

  return (
    <div>
      <FormControl fullWidth>
        <Select
          value={value}
          disableUnderline={disableUnderline}
          displayEmpty
          classes={{ root: classes.select }}
          onChange={(e) => {
            setValue(e.target.value);
            handleChange && handleChange(e);
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
        >
          <MenuItem value="" disabled>
            {placeholder}
          </MenuItem>
          <MenuItem key="all" value="All">
            All
          </MenuItem>
          {selectItems.map((item, index) => (
            <MenuItem key={index} value={item.value ? item.value : item.name}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default CustomFilter;
