import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  isFetching: false,
  entitlements: [],
};

const entitlementSlice = createSlice({
  name: 'entitlement',
  initialState: INITIAL_STATE,
  reducers: {
    entitlementFetchStart(state, action) {
      state.isFetching = true;
      state.entitlements = [];
    },
    entitlementFetchSuccess(state, { payload: { entitlements } }) {
      state.isFetching = false;
      state.entitlements = entitlements;
    },
    entitlementFetchError(state, action) {
      state.isFetching = false;
      state.entitlements = [];
    },
  },
});

export const { name, actions, reducer } = entitlementSlice;
export const {
  entitlementFetchStart,
  entitlementFetchSuccess,
  entitlementFetchError,
} = actions;
export default reducer;
