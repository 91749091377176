// routes
export const ROUTE_ROOT = '/';
export const ROUTE_SIGN_IN = '/signin';
export const ROUTE_AUTH_CALLBACK = '/auth/callback/';
export const ROUTE_UN_AUTHORIZED = '/unauthorized';
export const ROUTE_DASHBOARD = '/dashboard';
export const ROUTE_ENTITLEMENTS = '/entitlements';
export const ROUTE_TICKETS = '/tickets';
export const ROUTE_SUBSCRIPTIONS = '/subscriptions';
export const ROUTE_USERS = '/users';
export const ROUTE_USERPROFILE = '/userprofile';

//user-account-status
export const ACCOUNT_STATUS = {
  ALL: 'All',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  PENDING: 'PENDING',
};

export const FLOW = {
  CREATE: 'Create',
  EDIT: 'Edit',
  VIEW: 'View',
};

export const RANDOLI_USER_ACCESS_TOKEN = 'RANDOLI_USER_ACCESS_TOKEN';

// API gateway routes
export const PATH_IAM_SERVICE = 'core/iam';
export const PATH_CSP_AGGREGATOR_SERVICE = 'csp/aggregator';
export const PATH_TICKET_SERVICE = 'csp/ticket';
export const PATH_UPLOAD_SERVICE = 'core/files';

export const USER_GROUPS = {
  ORG_ADMIN: 'Org Admin',
  CSP_USER: 'CSP User',
};
