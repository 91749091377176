import React from 'react';
import { useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import { selectOrganization } from 'state/auth/selectors';
import TicketList from 'components/TicketList';
import useTickets from 'hooks/services/useTickets';

import TicketStatus from 'schema/ticket-status';

const useStyles = makeStyles((theme) => ({
  sectionTitle: theme.pageSectionTitle,
  ticketActivityRoot: {
    flexDirection: 'column',
  },
  rangeSelect: {
    paddingTop: '8px',
  },
  ticketLists: {
    flexWrap: 'nowrap',
    paddingTop: '25px',
    gap: '27px',
  },
  ticketListTitle: {
    fontFamily: 'Nunito',
    fontWeight: '700',
    fontStyle: 'normal',
    fontSize: '18px',
    lineHeight: '25px',
    color: 'rgba(15, 94, 247, 1)',
    paddingLeft: '5px',
  },
  [theme.breakpoints.down('sm')]: {
    ticketLists: {
      flexDirection: 'column',
      gap: '5',
    },
  },
}));
const TicketActivity = () => {
  const classes = useStyles();
  const organizationId = useSelector(selectOrganization);
  const {
    // isFetching: isFetchingOpenTickets,
    isFetchingMore: isFetchingMoreOpenTickets,
    data: openTickets,
    canFetchMore: canFetchMoreOpenTickets,
    fetchMore: fetchMoreOpen,
  } = useTickets({
    orgId: organizationId,
  });

  const {
    // isFetching: isFetchingInProgressTickets,
    isFetchingMore: isFetchingMoreInProgressTickets,
    data: inProgressTickets,
    canFetchMore: canFetchMoreOpenInProgressTickets,
    fetchMore: fetchMoreInProgress,
  } = useTickets({
    orgId: organizationId,
    status: TicketStatus.IN_PROGRESS.enumKey,
  });

  const {
    // isFetching: isFetchingResolvedTickets,
    isFetchingMore: isFetchingMoreResolvedTickets,
    data: resolvedTickets,
    canFetchMore: canFetchMoreResolvedTickets,
    fetchMore: fetchMoreResolved,
  } = useTickets({
    orgId: organizationId,
    status: TicketStatus.CLOSED.enumKey,
  });

  return (
    <Grid container classes={{ root: classes.ticketActivityRoot }}>
      <Grid item>
        <span className={classes.sectionTitle}>Tickets Activity</span>
      </Grid>
      {/* <Grid item classes={{ root: classes.rangeSelect }}>
        <span>Dropdown</span>
      </Grid> */}
      <Grid container classes={{ root: classes.ticketLists }}>
        <Grid item xs={12} md={4}>
          <span className={classes.ticketListTitle}>{'New Tickets'}</span>
          <Grid style={{ minHeight: '432px' }}>
            <TicketList
              tickets={openTickets}
              type={TicketStatus.OPENED.enumKey}
              isNextPageLoading={isFetchingMoreOpenTickets}
              hasNextPage={canFetchMoreOpenTickets}
              loadMore={fetchMoreOpen}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <span className={classes.ticketListTitle}>
            {'Tickets in Progress'}
          </span>
          <Grid style={{ minHeight: '432px' }}>
            <TicketList
              tickets={inProgressTickets}
              type={TicketStatus.IN_PROGRESS.enumKey}
              isNextPageLoading={isFetchingMoreInProgressTickets}
              hasNextPage={canFetchMoreOpenInProgressTickets}
              loadMore={fetchMoreInProgress}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <span className={classes.ticketListTitle}>{'Resolved Tickets'}</span>
          <Grid style={{ minHeight: '432px' }}>
            <TicketList
              tickets={resolvedTickets}
              type={TicketStatus.CLOSED.enumKey}
              isNextPageLoading={isFetchingMoreResolvedTickets}
              hasNextPage={canFetchMoreResolvedTickets}
              loadMore={fetchMoreResolved}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TicketActivity;
